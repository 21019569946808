/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
import Automations from './views/Automations.vue';

const EditAutomationBuilder = () => import('./views/EditAutomationBuilder.vue');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/automations'),
    name: 'automations_dashboard',
    meta: {
      permissions: ['administrator'],
    },
    component: Automations,
  },
  {
    path: frontendURL('accounts/:accountId/automations/:entrypointId'),
    name: 'automations_dashboard_entrypoint',
    meta: {
      permissions: ['administrator', 'agents'],
    },
    component: Automations,
    props: route => ({ entrypointId: route.params.entrypointId }),
  },
  {
    path: frontendURL(
      'accounts/:accountId/automations/:entrypointId/automation/:automationId'
    ),
    name: 'automations_builder',
    meta: {
      permissions: ['administrator', 'agents'],
    },
    component: EditAutomationBuilder,
    props: route => ({
      entrypointId: route.params.entrypointId,
      automationId: route.params.automationId,
    }),
  },
];
