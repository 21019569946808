<template>
  <div class="flex-grow">
    <formulate-form
      v-slot="{ hasErrors, isLoading }"
      v-model="subscription"
      name="subscription-settings-form"
      class="chatlyn-container my-8"
      @submit="subscriptionFormSubmit"
    >
      <div class="grid gap-8 md:grid-cols-3">
        <div class="col-span-1">
          <div class="text-lg font-medium text-gray-900 dark:text-gray-200">
            {{
              $t('CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.TITLE')
            }}
          </div>
          <p>
            {{
              $t('CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.NOTE')
            }}
          </p>
        </div>

        <div class="col-span-2 flex flex-col gap-4">
          <formulate-input
            name="legalName"
            :label="
              $t(
                'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.COMPANY_NAME'
              )
            "
            type="text"
            validation="required"
          />

          <formulate-input
            name="street"
            :label="
              $t(
                'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.STREET'
              )
            "
            type="text"
            validation="required"
          />

          <div class="flex flex-row flex-nowrap gap-4">
            <formulate-input
              class="w-1/3"
              name="zipcode"
              :label="
                $t(
                  'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.POST_CODE'
                )
              "
              type="text"
              validation="required"
            />

            <formulate-input
              class="w-2/3"
              name="city"
              :label="
                $t(
                  'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.CITY'
                )
              "
              type="text"
              validation="required"
            />
          </div>

          <formulate-input
            name="country"
            :label="
              $t(
                'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.COUNTRY'
              )
            "
            type="text"
            validation="required"
          />

          <formulate-input
            name="billingMail"
            :label="
              $t(
                'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.BILLING_EMAIL_ADDRESS'
              )
            "
            type="text"
            validation="required|email"
          />

          <formulate-input
            name="notificationMail"
            :label="
              $t(
                'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.NOTIFICATION_EMAIL_ADDRESS'
              )
            "
            type="text"
            validation="required|email"
          />
          <div>
            <woot-submit-button
              :disabled="hasErrors || isLoading"
              :button-text="$t('GENERAL_SETTINGS.SUBMIT')"
              :loading="isLoading"
              class="button--fixed-top"
            />
          </div>
        </div>
      </div>
    </formulate-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  data() {
    return {
      subscription: {},
      subscriptionSettingsForm: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      managementAccount: 'management/getAccount',
    }),
  },
  watch: {
    accountId: {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.$store.dispatch('management/getAccount', {
          accountId: newValue,
        });

        this.subscription = { ...this.managementAccount };
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.subscriptionSettingsForm = this.$formulate.registry.get(
        'subscription-settings-form'
      );
    });
  },
  methods: {
    async subscriptionFormSubmit() {
      if (
        !this.accountId ||
        !this.subscriptionSettingsForm ||
        this.subscriptionSettingsForm.hasErrors
      ) {
        return;
      }

      this.subscriptionSettingsForm.isLoading = true;

      await this.$store.dispatch('management/updateAccount', {
        accountId: this.accountId,
        account: this.subscription,
      });

      this.subscriptionSettingsForm.isLoading = false;

      this.showAlert(
        this.$t(
          'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.SUBMIT_SUCCESS_MESSAGE'
        )
      );
    },
  },
};
</script>

<style></style>
