<template>
  <button
    class="channel relative"
    @click="$emit('click')"
  >
    <span v-if="isNew" class="channel__new-tag">
      {{ $t('INBOX_MGMT.ADD.NEW') }}
    </span>
    <img :src="src" :alt="title" class="w-1/2 my-4 mx-auto" />
    <h3
      class="text-gray-800 dark:text-gray-100 text-base text-center capitalize"
    >
      {{ title }}
    </h3>
  </button>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },

    isNew: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.inactive {
  img {
    filter: grayscale(100%);
  }

  &:hover {
    @apply border-transparent shadow-none cursor-not-allowed;
  }
}
</style>
