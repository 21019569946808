<template>
  <div class="popover-select">
    <v-popover
      :placement="placement"
      popover-class="popover-select__container"
      trigger="manual"
      :offset="4"
      :open="isPopoverSelectOpen"
      :disabled="disabled"
      @apply-hide="handleAutoHide"
    >
      <div class="h-full" @click.stop.prevent="toggleIsPopoverSelectOpen">
        <slot />
      </div>

      <template slot="popover">
        <div
          class="flex h-full max-h-52 flex-col overflow-y-auto overflow-x-hidden"
        >
          <label
            v-if="hasSearch"
            class="popover-select__container__search brd z-10 flex flex-row items-center gap-2 rounded-t-md border-b border-solid bg-white px-2 py-1.5 dark:bg-gray-800"
          >
            <fluent-icon icon="search" :size="14" />

            <input
              v-model="search"
              class="!h-5 !bg-transparent !p-0 !text-sm !font-normal"
              :placeholder="searchPlaceholder"
              style="border: none"
            />
          </label>

          <ul
            v-if="searchedOptions && searchedOptions.length"
            class="popover-select__container__list"
          >
            <li
              v-for="option in searchedOptions"
              :key="option.value"
              class="popover-select__container__list__item relative flex flex-row items-center gap-2 rounded"
              :class="{
                'popover-select__container__list__item--selected surface-cta-highlight':
                  value === option.value,
              }"
            >
              <div
                v-if="option.hasDot"
                class="absolute right-0 top-0 flex items-center justify-center"
              >
                <div
                  class="absolute h-2 w-2 animate-ping rounded-full bg-woot-500 dark:bg-woot-400"
                />

                <div
                  class="h-2 w-2 rounded-full bg-woot-500 dark:bg-woot-400"
                />
              </div>

              <div
                v-if="option.value === 'separator'"
                class="h-[1px] w-full bg-gray-100 dark:bg-gray-700"
              />

              <woot-button
                v-else
                v-tooltip="{
                  content: showTooltip ? option.label : '',
                  classes: ['tooltip-important'],
                }"
                class="w-full"
                variant="clear"
                size="small"
                color-scheme="secondary"
                :icon="option.icon"
                :disabled="option.disabled"
                :data-testid="option.dataTestid"
                @click="handleOptionSelect(option.value)"
              >
                {{ option.label }}
              </woot-button>
            </li>
          </ul>

          <slot v-else-if="$slots['empty-state']" name="empty-state" />

          <div
            v-else
            class="flex h-full w-full items-center justify-center p-2 text-center text-sm text-gray-500"
            style="word-break: break-word"
          >
            {{ $t('CHATLYN_GENERAL.MESSAGES.NO_OPTIONS') }}
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
export default {
  name: 'PopoverSelect',

  props: {
    options: {
      type: Array,
      default: () => [],
    },

    value: {
      type: [String, Number],
      default: '',
    },

    placement: {
      type: String,
      default: 'top-start',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hasSearch: {
      type: Boolean,
      default: true,
    },

    searchPlaceholder: {
      type: String,
      default: '',
    },

    showTooltip: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPopoverSelectOpen: false,
      search: '',
    };
  },

  computed: {
    searchedOptions() {
      return this.hasSearch
        ? this.options.filter((option) =>
            option?.label?.toLowerCase()?.includes(this.search?.toLowerCase())
          )
        : this.options;
    },
  },

  watch: {
    isPopoverSelectOpen: {
      handler(newValue) {
        this.$emit('change-is-popover-select-open', newValue);
      },
    },
  },

  methods: {
    handleOptionSelect(value) {
      this.$emit('input', value);
      this.toggleIsPopoverSelectOpen();
    },

    toggleIsPopoverSelectOpen() {
      this.isPopoverSelectOpen = !this.isPopoverSelectOpen;
    },

    handleAutoHide() {
      this.isPopoverSelectOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-select {
  @apply h-full;

  ::v-deep .v-popover {
    @apply h-full;

    .trigger {
      @apply h-full;
    }
  }
}
</style>

<style lang="scss">
.popover-select__container {
  @apply max-h-52 w-40 max-w-[10rem] rounded-md border border-solid border-gray-100 bg-white shadow-lg shadow-gray-950/10 dark:border-gray-700 dark:bg-gray-800;

  .wrapper {
    @apply h-full;

    .popover-inner {
      @apply h-full;

      & > * {
        @apply h-full;
      }
    }
  }

  .popover-select__container__search {
    position: sticky;
    top: 0;
  }

  .popover-select__container__list {
    @apply m-0 flex list-none flex-col gap-0.5 p-2;

    .popover-select__container__list__item {
      button {
        @apply rounded p-0 px-2 py-1;

        border: none;

        span {
          @apply overflow-hidden text-ellipsis whitespace-nowrap;
        }
      }

      &.popover-select__container__list__item--selected {
        button {
          @apply text-woot-600 dark:text-woot-100;
        }
      }
    }
  }
}
</style>
