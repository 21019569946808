<template>
  <transition name="modal-fade">
    <div
      v-if="show"
      :class="modalClassName"
      transition="modal"
      :style="{ 'z-index': zIndex }"
      @mousedown="handleMouseDown"
    >
      <div
        :class="{
          'modal-container rtl:text-right shadow-md max-h-full overflow-auto relative bg-white dark:bg-gray-800 skip-context-menu': true,
          'rounded-xl w-[37.5rem]': !fullWidth,
          'items-center rounded-none flex h-full justify-center w-full':
            fullWidth,
          [size]: true,
        }"
        :style="modalContainerCustomStyles"
        @mouse.stop
        @mousedown="(event) => event.stopPropagation()"
      >
        <woot-button
          v-if="showCloseButton"
          color-scheme="secondary"
          icon="dismiss"
          variant="clear"
          type="button"
          class="absolute top-2 z-10 ltr:right-2 rtl:left-2"
          :style="{
            zIndex: 1,
          }"
          @click="close"
        />
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    closeOnBackdropClick: {
      type: Boolean,
      default: true,
    },
    show: Boolean,
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    modalType: {
      type: String,
      default: 'centered',
    },
    size: {
      type: String,
      default: '',
    },
    zIndex: {
      type: [String, Number],
      default: 9990,
    },
    width: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: String,
      default: '',
    },
    overflow: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mousedDownOnBackdrop: false,
    };
  },
  computed: {
    modalClassName() {
      const modalClassNameMap = {
        centered: '',
        'right-aligned': 'right-aligned',
      };

      return `modal-mask skip-context-menu ${
        modalClassNameMap[this.modalType] || ''
      }`;
    },
    modalContainerCustomStyles() {
      const customStyles = {};

      if (this.width.trim()) {
        customStyles.width = this.width;
      }

      if (this.maxWidth.trim()) {
        customStyles.maxWidth = this.maxWidth;
      }

      if (this.overflow.trim()) {
        customStyles.overflow = this.overflow;
      }

      return customStyles;
    },
  },
  mounted() {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.code === 'Escape') {
        if (!this.closeOnBackdropClick) {
          return;
        }

        this.close();
      }
    });

    document.body.addEventListener('mouseup', this.onMouseUp);
  },
  beforeDestroy() {
    document.body.removeEventListener('mouseup', this.onMouseUp);
    document.body.removeEventListener('keydown', this.close);
  },
  methods: {
    handleMouseDown() {
      this.mousedDownOnBackdrop = true;
    },
    close() {
      this.onClose();
    },
    onMouseUp() {
      if (this.mousedDownOnBackdrop) {
        this.mousedDownOnBackdrop = false;

        if (this.closeOnBackdropClick) {
          this.onClose();
        }
      }
    },
  },
};
</script>
