var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[_c('button',{staticClass:"w-10 h-10 my-2 flex items-center justify-center rounded-full relative transition-all duration-200 focus:outline-none outline-none",class:{
      'hover:bg-green-200 bg-green-100 dark:bg-green-800 dark:bg-opacity-50 hover:bg-opacity-80 dark:hover:bg-opacity-80' : _vm.isNotificationPanelActive,
      'hover:bg-green-100 hover:dark:bg-green-800 hover:dark:bg-opacity-50' : _vm.unreadCount,
      'hover:bg-gray-100 hover:dark:bg-gray-700 dark:bg-opacity-50 hover:bg-opacity-80 text-gray-500 dark:text-gray-400' : !_vm.isNotificationPanelActive && !_vm.unreadCount
    },on:{"click":_vm.openNotificationPanel}},[_c('fluent-icon',{class:{
        'text-green-500 dark:text-green-400': _vm.isNotificationPanelActive || _vm.unreadCount,
      },attrs:{"icon":"alert"}}),_vm._v(" "),(_vm.unreadCount)?_c('span',{staticClass:"bg-green-500 dark:bg-green-700 absolute -top-0.5 -right-1 px-1 h-4 w-4 text-xxs rounded-full animate-ping duration-500"}):_vm._e(),_vm._v(" "),(_vm.unreadCount)?_c('span',{staticClass:"text-white leading-4 font-bold bg-green-500 dark:bg-green-700 dark:bg-opacity-70 absolute -top-0.5 -right-1 px-1 h-4 text-xxs min-w-[1rem] rounded-full outline outline-white dark:outline-gray-800"},[_vm._v("\n      "+_vm._s(_vm.unreadCount)+"\n    ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }