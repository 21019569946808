export const TAGS = ['❶', '❷', '❸', '❹', '❺', '❻', '❼', '❽', '❾', '❿'];

export const SYSTEM_ATTRIBUTES_KEYS = {
  ID: 'contact.id',
  FULL_NAME: 'contact.full_name',
  FIRST_NAME: 'contact.first_name',
  LAST_NAME: 'contact.last_name',
  PHONE_NUMBER: 'contact.phone_number',
  EMAIL_ADDRESS: 'contact.email',
};

export const ATTRIBUTE_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  LINK: 'link',
  DATE: 'date',
  LIST: 'list',
  CHECKBOX: 'checkbox',
};
