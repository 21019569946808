<template>
  <button class="side-menu-btn" @click="onMenuItemClick">
    <fluent-icon :icon="icon" />
  </button>
</template>

<script>
import { BUS_EVENTS } from 'shared/constants/busEvents';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import types from '../store/mutation-types';
import { mapGetters } from 'vuex';

export default {
  mixins: [uiSettingsMixin],
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    ...mapGetters({
      isConversationSecondarySidebarShowing:
        'isConversationSecondarySidebarShowing',
      currentActiveRoute: 'getCurrentActiveRoute',
    }),
    isSidebarOpen() {
      const { show_secondary_sidebar: showSecondarySidebar } = this.uiSettings;
      return showSecondarySidebar;
    },
    icon() {
      if (this.currentActiveRoute === 'conversations') {
        return this.isConversationSecondarySidebarShowing
          ? 'sidebar-left'
          : 'sidebar-right';
      }

      return 'list';
    },
  },
  methods: {
    onMenuItemClick() {
      if (this.currentActiveRoute === 'conversations') {
        this.$store.commit(`${types.TOGGLE_CONVERSATION_SECONDARY_SIDEBAR}`);
      } else {
        this.$emitter.emit(BUS_EVENTS.TOGGLE_SIDEMENU);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.side-menu-btn {
  @apply text-gray-900 dark:text-gray-300;
}
</style>
