import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  userLanguage: '',
  account: null,
  chatBubbles: [],
  signatures: [],
  inboxOptOut: null,
  inboxOptOuts: {
    data: [],
    meta: {},
  },
  sms: {
    isEnabled: false,
    shortNames: [],
  },
  uiFlags: {
    // Creating
    isCreating: false,

    // Fetching
    isFetching: false,
    isSmsFetching: false,
    isOptOutsFetching: false,

    // Updating
    isUpdating: false,
    isSmsUpdating: false,
    isOptOutsUpdating: false,

    // Merging
    isMerging: false,

    // Deleting
    isDeleting: false,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
