<template>
  <div class="flex flex-nowrap">
    <woot-button
      class="aspect-square"
      :class="{
        active: value,
        'rounded-e-none': isImmediateTranslateButtonVisible,
      }"
      :is-disabled="isDisabled"
      icon="translate"
      color-scheme="secondary"
      variant="smooth"
      type="button"
      size="small"
      @click="handleChange"
    />

    <template v-if="isImmediateTranslateButtonVisible">
      <woot-button
        v-tooltip="{
          content: $t('CHATLYN_TRANSLATOR.TRANSLATE_TO', {
            LANGUAGE: selectedDeeplLanguage.name,
          }),
          delay: { show: 500, hide: 100 },
        }"
        class="appended-button max-w-[12.5rem] h-auto rounded-s-none"
        :is-disabled="isDisabled"
        color-scheme="secondary"
        variant="smooth"
        type="button"
        size="small"
        @click="handleTranslateText"
      >
        <span class="text-xs overflow-hidden text-ellipsis whitespace-nowrap">{{
          $t('CHATLYN_TRANSLATOR.TRANSLATE_TO', {
            LANGUAGE: selectedDeeplLanguage.name,
          })
        }}</span>
      </woot-button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DeeplAPI from '../../../../api/deepl';
import {
  CH_I18N_TO_DEEPL_LANGUAGES_KEYS,
  insertSpacesBetweenEmoji,
} from './constants';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  name: 'TranslatorToggler',
  mixins: [alertMixin],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: '',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    maxHeight: {
      type: [Number, undefined],
      default: undefined,
    },

    preferredLanguage: {
      type: String,
      default: 'en-US',
    },

    hasImmediateTranslateButton: {
      type: Boolean,
      default: true,
    },

    maxLength: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      deeplLanguages: 'deepl/getDeeplLanguages',
    }),

    selectedDeeplLanguage() {
      return (
        this.deeplLanguages.find(
          (deeplLanguage) =>
            deeplLanguage.code ===
              CH_I18N_TO_DEEPL_LANGUAGES_KEYS[this.preferredLanguage] ||
            deeplLanguage.code === this.preferredLanguage
        ) || {}
      );
    },

    isImmediateTranslateButtonVisible() {
      return (
        this.hasImmediateTranslateButton && this.selectedDeeplLanguage?.name
      );
    },
  },
  watch: {
    isLoading: {
      handler(newValue) {
        this.$emit('change-is-translating', newValue);
      },
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', !this.value);
    },

    async handleTranslateText() {
      try {
        if (
          !this.selectedDeeplLanguage ||
          !Object.keys(this.selectedDeeplLanguage).length
        ) {
          return;
        }

        this.isLoading = true;

        const response = await DeeplAPI.translateText(
          insertSpacesBetweenEmoji(this.message.trim()),
          this.selectedDeeplLanguage.code
        );

        if (
          typeof this.maxLength === 'number' &&
          response.data.text.length > this.maxLength
        ) {
          throw new Error(
            this.$t('CHATLYN_TRANSLATOR.ERROR.TRANSLATION_TOO_LONG')
          );
        }

        this.$emit('change-message', response.data.text);
      } catch (error) {
        this.showAlert(
          error.message || this.$t('CHATLYN_TRANSLATOR.ERROR.TRANSLATION')
        );
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
