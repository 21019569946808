var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal-fade"}},[(_vm.show)?_c('div',{class:_vm.modalClassName,style:({ 'z-index': _vm.zIndex }),attrs:{"transition":"modal"},on:{"mousedown":_vm.handleMouseDown}},[_c('div',{class:{
        'modal-container rtl:text-right shadow-md max-h-full overflow-auto relative bg-white dark:bg-gray-800 skip-context-menu': true,
        'rounded-xl w-[37.5rem]': !_vm.fullWidth,
        'items-center rounded-none flex h-full justify-center w-full':
          _vm.fullWidth,
        [_vm.size]: true,
      },style:(_vm.modalContainerCustomStyles),on:{"mouse":function($event){$event.stopPropagation();},"mousedown":(event) => event.stopPropagation()}},[(_vm.showCloseButton)?_c('woot-button',{staticClass:"absolute top-2 z-10 ltr:right-2 rtl:left-2",style:({
          zIndex: 1,
        }),attrs:{"color-scheme":"secondary","icon":"dismiss","variant":"clear","type":"button"},on:{"click":_vm.close}}):_vm._e(),_vm._v(" "),_vm._t("default")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }