var render = function render(){var _vm=this,_c=_vm._self._c;return _c('formulate-form',{staticClass:"column content-box create-message-template-modal",class:{
    'create-message-template-modal__rtl-view': _vm.currentLanguage === 'ar',
  },attrs:{"name":"create-message-template-form"}},[_c('woot-modal-header',{attrs:{"header-title":_vm.$t(_vm.modalHeader.title),"tool-tip-text":_vm.$t(_vm.modalHeader.tooltip)}}),_vm._v(" "),_c('div',{staticClass:"create-message-template-modal-content"},[(_vm.currentStep === _vm.CREATE_MESSAGE_TEMPLATE_STEP.TYPE)?_c('type-step',{attrs:{"message-template-type":_vm.messageTemplateType},on:{"change-message-template-type":_vm.changeMessageTemplateType}}):_vm._e(),_vm._v(" "),(_vm.currentStep === _vm.CREATE_MESSAGE_TEMPLATE_STEP.MAIN)?_c('main-info-step',{attrs:{"message-template":_vm.newMessageTemplate,"message-template-type":_vm.messageTemplateType},on:{"change-message-template":_vm.changeMessageTemplate}}):(
        _vm.currentStep === _vm.CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES &&
        !_vm.isLimitExceeded
      )?_c('languages-step',{attrs:{"languages":_vm.languages,"message-template":_vm.newMessageTemplate,"has-errors":_vm.isSomeFormHasErrors,"edit-mode":_vm.editMode,"available-languages":_vm.availableLanguages,"current-language":_vm.currentLanguage,"message-template-type":_vm.messageTemplateType},on:{"change-current-language":_vm.changeCurrentLanguage,"change-message-template":_vm.changeMessageTemplate}}):_vm._e()],1),_vm._v(" "),(!_vm.isLoading)?[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSmsNotEnabledForSmsTemplate),expression:"isSmsNotEnabledForSmsTemplate"}],staticClass:"callout alert m-8 p-6 text-center"},[_c('div',{staticClass:"text-lg font-semibold"},[_vm._v("\n        "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SMS_NOT_ENABLED.TITLE'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"mt-1 text-sm"},[_vm._v("\n        "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SMS_NOT_ENABLED.DESC'))+"\n      ")])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLimitExceeded),expression:"isLimitExceeded"}],staticClass:"callout alert m-8 p-6 text-center"},[_c('div',{staticClass:"text-lg font-semibold"},[_vm._v("\n        "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.LIMIT_EXCEEDED.TITLE'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"mt-1 text-sm"},[_vm._v("\n        "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.LIMIT_EXCEEDED.DESC'))+"\n      ")])])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"create-message-template-modal-footer",class:{
      'justify-between': !_vm.editMode,
      'justify-end': _vm.editMode,
    }},[(_vm.hasBackButton)?[(!_vm.editMode)?_c('woot-button',{attrs:{"variant":"smooth","color-scheme":"secondary","icon":"arrow-left"},on:{"click":_vm.goToPreviousStep}},[_vm._v("\n        "+_vm._s(_vm.$t('CHATLYN_GENERAL.BUTTON.BACK'))+"\n      ")]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.hasNextButton)?[_c('woot-button',{staticClass:"ms-auto flex-row-reverse",attrs:{"color-scheme":"success","is-disabled":_vm.isSomeFormHasErrors,"icon":"arrow-right"},on:{"click":_vm.goToNextStep}},[_vm._v("\n        "+_vm._s(_vm.$t('CHATLYN_GENERAL.BUTTON.NEXT'))+"\n      ")])]:_vm._e(),_vm._v(" "),(_vm.CREATE_MESSAGE_TEMPLATE_STEP.LANGUAGES === _vm.currentStep)?_c('span',{staticClass:"flex flex-row flex-nowrap items-center gap-2"},[(!_vm.isSmsMessageTemplate)?_c('span',{staticClass:"create-message-template-modal-footer-item-container"},[_c('woot-button',{staticClass:"create-message-template-modal-footer-item-button",class:{
            'rounded-e-none pe-2': _vm.translationCount > 1,
          },attrs:{"variant":"smooth","color-scheme":"secondary","type":"button","is-disabled":_vm.isDraftButtonDisabled},on:{"click":function($event){return _vm.saveLanguage(true, _vm.currentLanguage)}}},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.SAVE_LANGUAGE_DRAFT', {
              LANGUAGE: (
                _vm.MESSAGE_TEMPLATES_LANGUAGES_LABELS[_vm.currentLanguage] ||
                _vm.currentLanguage
              ).toUpperCase(),
            }))+"\n        ")]),_vm._v(" "),_c('popover-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.translationCount > 1),expression:"translationCount > 1"}],attrs:{"options":_vm.draftOptions},model:{value:(_vm.selectedDraftOption),callback:function ($$v) {_vm.selectedDraftOption=$$v},expression:"selectedDraftOption"}},[_c('woot-button',{staticClass:"create-message-template-modal-footer-item-popover-activator",attrs:{"variant":"smooth","color-scheme":"secondary","icon":"chevron-down","type":"button"}})],1)],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"create-message-template-modal-footer-item-container"},[_c('woot-button',{staticClass:"create-message-template-modal-footer-item-button",class:{
            'rounded-e-none pe-2': _vm.translationCount > 1,
          },attrs:{"color-scheme":"success","type":"button","is-disabled":_vm.isSubmitButtonDisabled},on:{"click":function($event){return _vm.saveLanguage(false, _vm.currentLanguage)}}},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.PUBLISH_LANGUAGE', {
              LANGUAGE: (
                _vm.MESSAGE_TEMPLATES_LANGUAGES_LABELS[_vm.currentLanguage] ||
                _vm.currentLanguage
              ).toUpperCase(),
            }))+"\n        ")]),_vm._v(" "),_c('popover-select',{directives:[{name:"show",rawName:"v-show",value:(_vm.translationCount > 1),expression:"translationCount > 1"}],attrs:{"options":_vm.saveOptions},model:{value:(_vm.selectedSaveOption),callback:function ($$v) {_vm.selectedSaveOption=$$v},expression:"selectedSaveOption"}},[_c('woot-button',{staticClass:"create-message-template-modal-footer-item-popover-activator",attrs:{"color-scheme":"success","icon":"chevron-down","type":"button"}})],1)],1)]):_vm._e()],2),_vm._v(" "),_c('chatlyn-spinner',{attrs:{"is-visible":_vm.isLoading}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }