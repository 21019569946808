<template>
  <div
    v-tooltip.top="{
      content: tooltipText,
      delay: { show: 500, hide: 0 },
      hideOnClick: true,
    }"
    class="leading-none"
  >
    {{ timeAgoValue }}
  </div>
</template>

<script>
// Libs
import {
  differenceInDays,
  differenceInMinutes,
  format,
  isSameWeek,
} from 'date-fns';

// Mixins
import timeMixin from 'dashboard/mixins/time';

// Constants
const REFRESH_TIME = 60000; // 60000 = 1 min

// Variables
let refreshTimeInterval;

export default {
  name: 'TimeAgo',

  mixins: [timeMixin],

  props: {
    isAutoRefreshEnabled: {
      type: Boolean,
      default: true,
    },

    lastActivityTimestamp: {
      type: [String, Date, Number],
      default: '',
    },

    createdAtTimestamp: {
      type: [String, Date, Number],
      default: '',
    },
  },

  data() {
    return {
      currentDate: Date.now(),
    };
  },

  computed: {
    lastActivityDate() {
      return new Date(this.lastActivityTimestamp * 1000);
    },

    createdAtDate() {
      return new Date(this.createdAtTimestamp * 1000);
    },

    minsDifference() {
      const startDate = new Date(format(this.currentDate, 'MM/dd/yyyy HH:mm'));
      const endDate = new Date(
        format(this.lastActivityDate, 'MM/dd/yyyy HH:mm')
      );

      return differenceInMinutes(startDate, endDate);
    },

    daysDifference() {
      const startDate = new Date(format(this.currentDate, 'MM/dd/yyyy'));
      const endDate = new Date(format(this.lastActivityDate, 'MM/dd/yyyy'));

      return differenceInDays(startDate, endDate);
    },

    isTheSameWeek() {
      const startDate = new Date(format(this.currentDate, 'MM/dd/yyyy'));
      const endDate = new Date(format(this.lastActivityDate, 'MM/dd/yyyy'));

      return isSameWeek(endDate, startDate);
    },

    timeAgoValue() {
      if (this.minsDifference < 1) {
        return this.$t('CHAT_LIST.CHAT_TIME_STAMP.JUST_NOW');
      }

      if (this.daysDifference === 0) {
        return format(this.lastActivityDate, 'HH:mm');
      }

      if (this.daysDifference === 1) {
        return this.$t('CHAT_LIST.CHAT_TIME_STAMP.YESTERDAY');
      }

      if (this.daysDifference < 7 && this.isTheSameWeek) {
        return format(this.lastActivityDate, 'EEEE');
      }

      return format(this.lastActivityDate, 'dd.MM.yy');
    },

    tooltipText() { 
      const createdAt = this.$t('CHAT_LIST.CHAT_TIME_STAMP.CREATED.FULL', {
        DATE: format(this.createdAtDate, 'dd.MM.yyyy'),
        TIME: format(this.createdAtDate, 'HH:mm'),
      });

      const lastActivityAt = this.$t(
        'CHAT_LIST.CHAT_TIME_STAMP.LAST_ACTIVITY.FULL',
        {
          DATE: format(this.lastActivityDate, 'dd.MM.yyyy'),
          TIME: format(this.lastActivityDate, 'HH:mm'),
        }
      );

      let tooltip = "";
      tooltip += this.createdAtTimestamp ? `${createdAt}\n` : '';
      tooltip += this.lastActivityTimestamp ? `${lastActivityAt}` : '';

      return tooltip;
    },
  },

  watch: {
    isAutoRefreshEnabled: {
      handler(newValue) {
        clearInterval(refreshTimeInterval);

        if (!newValue) {
          return;
        }

        refreshTimeInterval = setInterval(() => {
          this.refreshCurrentDate();
        }, REFRESH_TIME);
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    clearInterval(refreshTimeInterval);
  },

  methods: {
    refreshCurrentDate() {
      this.currentDate = Date.now();
    },
  },
};
</script>
