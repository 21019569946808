import { render, staticRenderFns } from "./MessageTemplatesTable.vue?vue&type=template&id=15270cee&scoped=true&"
import script from "./MessageTemplatesTable.vue?vue&type=script&lang=js&"
export * from "./MessageTemplatesTable.vue?vue&type=script&lang=js&"
import style0 from "./MessageTemplatesTable.vue?vue&type=style&index=0&id=15270cee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15270cee",
  null
  
)

export default component.exports