<template>
  <div class="flex w-full flex-col items-start">
    <h2
      class="break-words text-xl font-medium mb-1 text-gray-800 dark:text-gray-100"
    >
      {{ headerTitle }}
    </h2>
    <p
      v-dompurify-html="headerContent"
      class="w-full text-sm text-gray-600 dark:text-gray-300"
    />
  </div>
</template>

<script>
export default {
  props: {
    headerTitle: { type: String, default: '' },
    headerContent: { type: String, default: '' },
  },
};
</script>
