<template>
  <header
    class="surface-panel-blur brd-highlight flex h-14 flex-shrink-0 items-center justify-between border-b py-2 pe-2 ps-4"
  >
    <h1 class="page-title flex flex-nowrap items-center gap-2">
      {{ headerTitle }}
    </h1>

    <div class="flex items-center gap-2">
      <woot-input
        v-if="hasSearch"
        icon="search"
        :value="searchQuery"
        :placeholder="$t('CHATLYN_MESSAGE_TEMPLATE.SEARCH_QUERY_PLACEHOLDER')"
        @input="handleSearchQueryChange"
      />

      <woot-button
        v-if="!hideCreateButton"
        color-scheme="success"
        icon="add-circle"
        data-testid="create-new-message-template"
        @click="$emit('toggle-show-create-message-template-modal')"
      >
        {{ createButtonText }}
      </woot-button>
    </div>
  </header>
</template>

<script>
import { MESSAGE_TEMPLATES_CHANNEL_TYPE } from '../constants';

export default {
  name: 'MessageTemplatesHeader',
  props: {
    headerTitle: {
      type: String,
      default: '',
    },

    hasSearch: {
      type: Boolean,
      default: false,
    },

    searchQuery: {
      type: String,
      default: '',
    },

    hideCreateButton: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: '',
    },

    messageTemplateType: {
      type: String,
      default: MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP,
    },
  },

  computed: {
    createButtonText() {
      return this.$t(
        `CHATLYN_MESSAGE_TEMPLATE.BUTTON.NEW_TEMPLATE.${this.messageTemplateType.toUpperCase()}`
      );
    },
  },

  methods: {
    onToggleDeleteSegmentsModal() {
      this.$emit('on-toggle-delete-filter');
    },

    onToggleSendMessage() {
      this.$emit('on-toggle-send-message');
    },

    handleSearchQueryChange(newValue) {
      this.$emit('change-search-query', newValue);
    },
  },
};
</script>
