// import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_MANAGEMENT_USER_LANGUAGE]($state, payload) {
    $state.userLanguage = payload;
  },

  [types.SET_MANAGEMENT_ACCOUNT]($state, payload) {
    $state.account = payload;
  },

  [types.SET_MANAGEMENT_UI_FLAGS]($state, payload) {
    $state.uiFlags = { ...$state.uiFlags, ...payload };
  },

  [types.SET_MANAGEMENT_CHAT_BUBBLES]($state, payload) {
    $state.chatBubbles = payload;
  },

  [types.SET_MANAGEMENT_INBOX_OPT_OUT]($state, payload) {
    $state.inboxOptOut = payload;
  },

  [types.CLEAR_MANAGEMENT_CHAT_BUBBLES]($state) {
    $state.chatBubbles = [];
  },

  [types.SET_MANAGEMENT_SIGNATURES]($state, payload) {
    $state.signatures = payload;
  },

  [types.CLEAR_MANAGEMENT_SIGNATURES]($state) {
    $state.signatures = [];
  },

  [types.CLEAR_MANAGEMENT_INBOX_OPT_OUT]($state) {
    $state.inboxOptOut = null;
  },

  [types.SET_MANAGEMENT_SMS]($state, payload) {
    $state.sms = payload;
  },

  [types.SET_MANAGEMENT_SMS]($state, payload) {
    $state.sms = payload;
  },

  [types.SET_MANAGEMENT_INBOX_OPT_OUTS]($state, payload) {
    $state.inboxOptOuts = payload;
  },

  [types.CLEAR_MANAGEMENT_INBOX_OPT_OUTS]($state) {
    $state.inboxOptOuts = {
      data: [],
      meta: {},
    };
  },

  [types.CLEAR_MANAGEMENT_SMS]($state) {
    $state.sms = {
      isEnabled: false,
      shortNames: [],
    };
  },
};
