<template>
  <router-link
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
  >
    <li
      class="mb-1 flex h-7 select-none items-center rounded px-2 transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-800"
      :class="{
        'bg-woot-50 hover:bg-woot-50 dark:bg-woot-800 hover:dark:bg-woot-800':
          isActive,
        'max-w-full overflow-hidden text-ellipsis whitespace-nowrap':
          shouldTruncate,
      }"
      @click="navigate"
    >
      <a
        :href="href"
        class="inline-flex w-full max-w-full items-center text-left"
      >
        <span
          v-if="bigIcon"
          class="badge--icon"
          :style="{ backgroundColor: 'transparent' }"
        >
          <fluent-icon :icon="bigIcon" size="12" />
        </span>
        <span
          v-else-if="icon"
          class="me-1 inline-flex w-4 items-center justify-center bg-transparent dark:bg-transparent"
        >
          <fluent-icon
            class="text-xs text-gray-600 dark:text-gray-300"
            :class="{
              'text-woot-600 dark:text-woot-400': isActive,
            }"
            :icon="icon"
            size="12"
          />
        </span>

        <span
          v-if="labelColor"
          class="me-1 inline-flex aspect-square h-3 w-3 rounded bg-gray-100"
          :style="{ backgroundColor: labelColor }"
        />
        <div
          class="group relative flex w-full items-center justify-between overflow-hidden text-ellipsis whitespace-nowrap"
        >
          <span
            :title="menuTitle"
            class="text-sm text-gray-700 dark:text-gray-200"
            :class="{
              'text-woot-600 dark:text-woot-400': isActive,
              'max-w-full overflow-hidden text-ellipsis whitespace-nowrap':
                shouldTruncate,
            }"
            :style="{ width: '90%' }"
            @mouseenter.once="handleTruncatedEnter"
          >
            {{ label }}
          </span>

          <span
            v-if="showChildCount"
            class="ms-1 rounded bg-gray-50 px-1 py-0 text-xxs font-medium opacity-100 group-focus-within:opacity-0 group-hover:opacity-0 dark:bg-gray-800"
            :class="
              isCountZero
                ? `text-gray-400 dark:text-gray-400`
                : `text-gray-600 dark:text-gray-100`
            "
          >
            {{ childItemCount }}
          </span>

          <span
            v-if="menuOptions && menuOptions.length"
            class="absolute right-0 opacity-0 group-focus-within:opacity-100 group-hover:opacity-100"
          >
            <popover-select
              v-model="selectedMenuOption"
              class="secondary-child-nav-item__popover"
              placement="bottom-end"
              :options="menuOptions"
            >
              <button
                class="flex h-4 w-4 rounded"
                data-testid="dot-menu-toggler"
              >
                <fluent-icon class="h-full w-full" icon="more-horizontal" />
              </button>
            </popover-select>
          </span>
        </div>

        <span
          v-if="warningIcon"
          class="mr-1 inline-flex rounded-sm bg-gray-100"
        >
          <fluent-icon
            v-tooltip.top-end="$t('SIDEBAR.REAUTHORIZE')"
            class="text-xxs text-red-500 dark:text-red-300"
            :icon="warningIcon"
            size="12"
          />
        </span>
      </a>
    </li>
  </router-link>
</template>
<script>
import PopoverSelect from '../../../../shared/components/ui/PopoverSelect.vue';

export default {
  components: { PopoverSelect },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    bigIcon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
    menuOptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    showIcon() {
      return {
        'overflow-hidden whitespace-nowrap text-ellipsis': this.shouldTruncate,
      };
    },
    isCountZero() {
      return this.childItemCount === 0;
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },

    selectedMenuOption: {
      get() {
        return '';
      },

      async set(newValue) {
        const matchedOption = this.menuOptions.find(
          (menuOption) => menuOption.value === newValue
        );

        if (!matchedOption || typeof matchedOption.action !== 'function') {
          return;
        }

        // eslint-disable-next-line no-underscore-dangle
        await matchedOption.action(this.item?._id || this.item?.id);
      },
    },
  },
  methods: {
    handleTruncatedEnter(event) {
      const target = event?.target;
      const child = event?.target?.children[0];

      if (!target || !child) {
        return;
      }

      const sizeDifference = target.clientWidth - child.offsetWidth;

      if (sizeDifference >= 0) {
        return;
      }

      child.style.setProperty('--marginLeft', `${sizeDifference}px`);
      child.style.setProperty(
        '--transitionDuration',
        `${sizeDifference * -15}ms`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-child-nav-item__popover {
  ::v-deep {
    .v-popover {
      @apply flex;
    }
  }
}
</style>
