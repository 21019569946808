<template>
  <div class="flex flex-col gap-2">
    <FormulateInput
      v-if="isFormulateFormComponent"
      :id="id"
      ref="textarea"
      v-model="formulateValue"
      class="formulate-resizable-text-area"
      type="textarea"
      :placeholder="placeholder"
      :validation="validation || undefined"
      :validation-name="validationName || undefined"
      :validation-messages="validationMessages"
      :validation-rules="validationRules"
      :error-behavior="errorBehavior || undefined"
      :help="help || undefined"
      :style="{
        height: 'auto',
        '--resizeTextAreaMinHeight': `${minHeight}rem`,
      }"
      :maxlength="maxLength > 0 ? maxLength : undefined"
      :disabled="isDisabled"
      @focus="onFocus"
      @keyup="onKeyup"
      @blur="onBlur"
    />

    <textarea
      v-else
      :id="id"
      ref="textarea"
      class="transparent borderless max-h-[6rem] resize-none py-2"
      :style="{
        minHeight: `${minHeight}rem`,
        maxHeight: `${minHeight * 2}rem`,
      }"
      :placeholder="placeholder"
      :rows="rows"
      :value="value"
      :maxlength="maxLength > 0 ? maxLength : undefined"
      :disabled="isDisabled"
      @input="onInput"
      @focus="onFocus"
      @keyup="onKeyup"
      @blur="onBlur"
    />

    <div v-if="hasFooter" class="flex flex-col gap-2">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import {
  appendSignature,
  removeSignature,
  extractTextFromMarkdown,
} from 'dashboard/helper/editorHelper';
import { createTypingIndicator } from '@chatwoot/utils';

const TYPING_INDICATOR_IDLE_TIME = 4000;
export default {
  props: {
    id: {
      type: String,
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    minHeight: {
      type: Number,
      default: 2,
    },

    isFormulateFormComponent: {
      type: Boolean,
      default: false,
    },

    validation: {
      type: String,
      default: undefined,
    },

    validationName: {
      type: String,
      default: '',
    },

    validationRules: {
      type: [Object, undefined],
      default: undefined,
    },

    validationMessages: {
      type: [Object, undefined],
      default: undefined,
    },

    errorBehavior: {
      type: String,
      default: '',
    },

    help: {
      type: String,
      default: '',
    },

    maxLength: {
      type: [Number, undefined],
      default: undefined,
    },

    signature: {
      type: String,
      default: '',
    },

    rows: {
      type: Number,
      default: 2,
    },

    // add this as a prop, so that we won't have to include uiSettingsMixin
    sendWithSignature: {
      type: Boolean,
      default: false,
    },

    // allowSignature is a kill switch, ensuring no signature methods are triggered except when this flag is true
    allowSignature: {
      type: Boolean,
      default: false,
    },

    canReply: {
      type: Boolean,
      default: false,
    },

    hasFooter: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typingIndicator: createTypingIndicator(
        () => {
          this.$emit('typing-on');
        },
        () => {
          this.$emit('typing-off');
        },
        TYPING_INDICATOR_IDLE_TIME
      ),
    };
  },
  computed: {
    formulateValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.onInput({ target: { value: newValue } });
      },
    },
    cleanedSignature() {
      // clean the signature, this will ensure that we don't have
      // any markdown formatted text in the signature
      return extractTextFromMarkdown(this.signature);
    },
    isConversationRoute() {
      return ['inbox_conversation', 'conversation_through_inbox'].includes(
        this.$route.name
      );
    },
    isDisabled() {
      return this.disabled || (!this.canReply && this.isConversationRoute);
    },
  },
  watch: {
    value: {
      handler() {
        // 🚨 watch triggers every time the value is changed, we cannot set this to focus then
        // when this runs, it sets the cursor to the end of the body, ignoring the signature
        // Suppose if someone manually set the cursor to the middle of the body
        // and starts typing, the cursor will be set to the end of the body
        // A surprise cursor jump? Definitely not user-friendly.
        this.$nextTick(() => {
          this.resizeTextarea();
        });
      },
      immediate: true,
    },
    sendWithSignature(newValue) {
      if (this.allowSignature) {
        this.toggleSignatureInEditor(newValue);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.value) {
        this.resizeTextarea();
        this.setCursor();
      } else {
        this.focus();
      }
    });
  },
  methods: {
    resizeTextarea() {
      this.$el.style.height = 'auto';
      const element = document.getElementById(this.id) || this.$el;

      if (!this.value) {
        element.style.height = `${this.minHeight}rem`;
      } else {
        element.style.height = `${element.scrollHeight}px`;
      }
    },
    // The toggleSignatureInEditor gets the new value from the
    // watcher, this means that if the value is true, the signature
    // is supposed to be added, else we remove it.
    toggleSignatureInEditor(signatureEnabled) {
      const valueWithSignature = signatureEnabled
        ? appendSignature(this.value, this.cleanedSignature)
        : removeSignature(this.value, this.cleanedSignature);

      this.$emit('input', valueWithSignature);

      this.$nextTick(() => {
        this.resizeTextarea();
        this.setCursor();
      });
    },
    setCursor() {
      const bodyWithoutSignature = removeSignature(
        this.value,
        this.cleanedSignature
      );

      // only trim at end, so if there are spaces at the start, those are not removed
      const bodyEndsAt = bodyWithoutSignature.trimEnd().length;
      const textarea = this.$refs.textarea;

      if (!textarea) {
        return;
      }

      if (this.isFormulateFormComponent) {
        const textareaElements = textarea.$el.getElementsByTagName('textarea');

        if (!textareaElements.length) {
          return;
        }

        textareaElements[0].focus();
        textareaElements[0].setSelectionRange(bodyEndsAt, bodyEndsAt);
      } else {
        textarea.focus();
        textarea.setSelectionRange(bodyEndsAt, bodyEndsAt);
      }
    },
    onInput(event) {
      this.$emit('input', event.target.value);
      this.resizeTextarea();
    },
    onKeyup() {
      this.typingIndicator.start();
    },
    onBlur() {
      this.typingIndicator.stop();
      this.$emit('blur');
    },
    onFocus() {
      this.$emit('focus');
    },
    focus() {
      if (!this.$refs[this.refValue]?.focus) {
        return;
      }
      this.$refs[this.refValue].focus();
    },
  },
};
</script>

<style lang="scss">
.formulate-resizable-text-area {
  height: auto !important;

  textarea {
    @apply max-h-[calc(var(--resizeTextAreaMinHeight)*2)] min-h-[var(--resizeTextAreaMinHeight)] resize-none;
  }

  .formulate-input-errors {
    @apply mt-2;
  }
}
</style>
