var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-grow"},[_c('formulate-form',{staticClass:"chatlyn-container my-8",attrs:{"name":"subscription-settings-form"},on:{"submit":_vm.subscriptionFormSubmit},scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors, isLoading }){return [_c('div',{staticClass:"grid gap-8 md:grid-cols-3"},[_c('div',{staticClass:"col-span-1"},[_c('div',{staticClass:"text-lg font-medium text-gray-900 dark:text-gray-200"},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.TITLE'))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.NOTE'))+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"col-span-2 flex flex-col gap-4"},[_c('formulate-input',{attrs:{"name":"legalName","label":_vm.$t(
              'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.COMPANY_NAME'
            ),"type":"text","validation":"required"}}),_vm._v(" "),_c('formulate-input',{attrs:{"name":"street","label":_vm.$t(
              'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.STREET'
            ),"type":"text","validation":"required"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-row flex-nowrap gap-4"},[_c('formulate-input',{staticClass:"w-1/3",attrs:{"name":"zipcode","label":_vm.$t(
                'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.POST_CODE'
              ),"type":"text","validation":"required"}}),_vm._v(" "),_c('formulate-input',{staticClass:"w-2/3",attrs:{"name":"city","label":_vm.$t(
                'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.CITY'
              ),"type":"text","validation":"required"}})],1),_vm._v(" "),_c('formulate-input',{attrs:{"name":"country","label":_vm.$t(
              'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.COUNTRY'
            ),"type":"text","validation":"required"}}),_vm._v(" "),_c('formulate-input',{attrs:{"name":"billingMail","label":_vm.$t(
              'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.BILLING_EMAIL_ADDRESS'
            ),"type":"text","validation":"required|email"}}),_vm._v(" "),_c('formulate-input',{attrs:{"name":"notificationMail","label":_vm.$t(
              'CHATLYN_SUBSCRIPTION.FORM.BUSINESS_INFORMATION_SECTION.NOTIFICATION_EMAIL_ADDRESS'
            ),"type":"text","validation":"required|email"}}),_vm._v(" "),_c('div',[_c('woot-submit-button',{staticClass:"button--fixed-top",attrs:{"disabled":hasErrors || isLoading,"button-text":_vm.$t('GENERAL_SETTINGS.SUBMIT'),"loading":isLoading}})],1)],1)])]}}]),model:{value:(_vm.subscription),callback:function ($$v) {_vm.subscription=$$v},expression:"subscription"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }