<!-- eslint-disable prettier/prettier -->
<template>
  <div class="language-bar">
    <div
      v-if="!isListViewEnabled && Array.isArray(languages) && languages.length"
      class="flex flex-row flex-nowrap overflow-auto"
      :style="{
        maxWidth: languagesListMaxWidth,
      }"
    >
      <span
        v-for="language in languages"
        :key="language.name"
        class="language-bar-language"
        :class="{
          'language-bar-language--selected': currentLanguage === language.name,
        }"
        @click="onChange(language.name)"
      >
        <span class="language-bar-language-container">
          <span
            v-if="hasColorMark"
            class="status-indicator"
            :style="{
              background: getLanguageMarkColor(language.name),
            }"
          />
          <span class="language-bar-language-container-text uppercase">
            {{
              MESSAGE_TEMPLATES_LANGUAGES_LABELS[language.name] || language.name
            }}

            <template v-if="hasAdditionalText">
              {{
                $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TEMPLATE_LANGUAGE')
              }}
            </template>
          </span>

          <span
            v-if="getIsRemoveButtonVisible(language.name)"
            class="transition-colors duration-200 hover:bg-woot-50"
            :style="{
              borderRadius: '0.157rem',
              padding: '0.188rem',
            }"
            @click.prevent="handleRemoveButtonClick(language.name)"
          >
            <fluent-icon icon="dismiss" :size="12" />
          </span>
        </span>

        <span class="language-bar-language-b-line" />
      </span>
    </div>

    <span v-if="hasAddButton">
      <popover-select
        v-if="hasPopover"
        v-model="selectedLanguage"
        :options="availableLanguages"
      >
        <template #default>
          <woot-button
            :style="{
              padding: 0,
              height: '100%',
              margin: '0',
            }"
            color-scheme="primary"
            variant="clear"
            icon="add-circle"
            icon-type="solid"
            type="button"
          />
        </template>

        <template #empty-state>
          <div class="message-template_additional-languages--empty-state">
            <span
              class="message-template_additional-languages--empty-state__title text-sm"
            >
              {{
                $t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.NO_ADDITIONAL_LANGUAGES_MESSAGE.TITLE'
                )
              }}
            </span>

            <span
              v-dompurify-html="
                $t(
                  'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.NO_ADDITIONAL_LANGUAGES_MESSAGE.MESSAGE',
                  { LINK: settingsRoute }
                )
              "
              class="message-template_additional-languages--empty-state__message text-xs"
            />
          </div>
        </template>
      </popover-select>

      <woot-button
        v-else
        :style="{
          padding: 0,
          height: '100%',
          margin: '0',
        }"
        color-scheme="primary"
        variant="clear"
        icon="add-circle"
        icon-type="solid"
        type="button"
        @click="handleAddButtonClick"
      />
    </span>

    <span v-if="hasRightSide" class="ml-auto flex items-center">
      <slot name="right-side" />
    </span>
  </div>
</template>

<script>
import PopoverSelect from '../../../../../shared/components/ui/PopoverSelect.vue';
import WootButton from '../../../../components/ui/WootButton.vue';
import {
  MESSAGE_TEMPLATES_STATUSES_OPTION,
  MESSAGE_TEMPLATES_LANGUAGES_LABELS,
} from '../constants';

export default {
  name: 'LanguageBar',
  components: { WootButton, PopoverSelect },
  props: {
    languages: {
      type: Array,
      default: () => [],
    },

    translations: {
      type: Array,
      default: () => [],
    },

    availableLanguages: {
      type: Array,
      default: () => [],
    },

    mainLanguage: {
      type: String,
      default: 'en',
    },

    currentLanguage: {
      type: String,
      default: 'en',
    },

    customMarkColor: {
      type: String,
      default: '#fff',
    },

    isListViewEnabled: {
      type: Boolean,
      default: false,
    },

    hasColorMark: {
      type: Boolean,
      default: false,
    },

    hasRemoveButton: {
      type: Boolean,
      default: false,
    },

    hasMinimumOneLanguage: {
      type: Boolean,
      default: true,
    },

    hasAddButton: {
      type: Boolean,
      default: false,
    },

    hasRightSide: {
      type: Boolean,
      default: false,
    },

    hasAdditionalText: {
      type: Boolean,
      default: true,
    },

    hasPopover: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      MESSAGE_TEMPLATES_LANGUAGES_LABELS,
    };
  },

  computed: {
    languagesListMaxWidth() {
      if (this.hasRightSide && this.hasAddButton) {
        return 'calc(80% - 3.75rem)';
      }

      if (this.hasRightSide && !this.hasAddButton) {
        return '80%';
      }

      if (!this.hasRightSide && this.hasAddButton) {
        return 'calc(100% - 3.125rem)';
      }

      return '100%';
    },

    selectedLanguage: {
      get() {
        return this.currentLanguage;
      },

      set(newValue) {
        this.handleAddButtonClick(newValue);
      },
    },

    messageTemplateStatusColor() {
      if (!this.statusOption || !this.statusOption.color) {
        return '';
      }

      return this.statusOption.color;
    },

    settingsRoute() {
      const route = this.$router.resolve({
        name: 'settings_home',
      });

      return `${window.location.origin}${route.href}/general`;
    },
  },
  methods: {
    getIsRemoveButtonVisible(language) {
      return !(
        this.currentLanguage !== language ||
        !this.hasRemoveButton ||
        (this.hasMinimumOneLanguage && this.languages.length <= 1)
      );
    },

    onChange(language) {
      this.$emit('change', language);
    },

    getLanguageMarkColor(language) {
      const matchedTransition = this.translations.find(
        (translation) => translation.language === language
      );

      const statusOption =
        MESSAGE_TEMPLATES_STATUSES_OPTION[
          matchedTransition?.status?.toUpperCase() || ''
        ];

      if (!statusOption || !statusOption.color) {
        return this.customMarkColor;
      }

      return statusOption.color;
    },

    handleRemoveButtonClick(language) {
      this.$emit('handle-remove-button-click', language);
    },

    handleAddButtonClick(newValue) {
      this.$emit('handle-add-button-click', newValue);
    },
  },
};
</script>

<style lang="scss">
.language-bar {
  @apply flex w-full flex-1 flex-row flex-nowrap gap-2 border-b dark:border-b-gray-700;

  .language-bar-language {
    @apply flex h-full cursor-pointer select-none flex-col;

    .language-bar-language-container {
      @apply flex flex-grow flex-row flex-nowrap items-center justify-center gap-1 p-2 text-sm leading-none;

      .language-bar-language-container-text {
        @apply text-gray-700 dark:text-gray-400;
      }
    }

    .language-bar-language-b-line {
      @apply block h-0.5 w-full flex-shrink-0 bg-transparent;
    }

    &.language-bar-language--selected {
      .language-bar-language-container {
        .language-bar-language-container-text {
          @apply text-woot-500 dark:text-woot-300;
        }
      }

      .language-bar-language-b-line {
        @apply bg-woot-500 dark:bg-woot-300;
      }
    }
  }
}

.message-template_additional-languages--empty-state {
  @apply flex flex-col flex-nowrap gap-1 rounded-lg bg-gray-100 p-2 text-center text-gray-900 dark:bg-gray-800 dark:text-gray-300;

  .message-template_additional-languages--empty-state__message a {
    @apply text-xs text-woot-500;
  }
}
</style>
