<template>
  <span
    v-if="priority"
    v-tooltip="{
      content: tooltipText,
      delay: { show: 500, hide: 0 },
      hideOnClick: true,
    }"
    class="priority-flag"
    :class="priority"
  >
    <fluent-icon
      :size="size"
      icon="flag"
      type="filled"
      class="priority-flag__mark"
    />
  </span>
</template>

<script>
import { CONVERSATION_PRIORITY } from '../../../../shared/constants/messages';

export default {
  name: 'PriorityFlag',
  props: {
    priority: {
      type: String,
      default: '',
      validate: (value) =>
        [...Object.values(CONVERSATION_PRIORITY), ''].includes(value),
    },

    size: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      CONVERSATION_PRIORITY,
    };
  },
  computed: {
    tooltipText() {
      return this.$t(
        `CONVERSATION.PRIORITY.OPTIONS.${this.priority.toUpperCase()}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.priority-flag {
  &.low {
    @apply text-gray-400;
  }

  &.medium {
    @apply text-blue-500;
  }

  &.high {
    @apply text-yellow-500;
  }

  &.urgent {
    @apply text-red-600;
  }
}
</style>
