import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import accountMixin from 'dashboard/mixins/account';

export default {
  mixins: [alertMixin, accountMixin],
  data() {
    return {
      showDeleteConfirmation: false,
      selectedCredential: '',
    };
  },
  computed: {
    ...mapGetters({
      credentials: 'credentials/getCredentials',
      credential: 'credentials/getCredentialItem',
      uiFlags: 'credentials/getUIFlags',
    }),
    statusMap() {
      return {
        live: {
          color: 'bg-green-500',
          text: this.$t('CHATLYN_CREDENTIALS.STATUS.LIVE'),
        },
        expired: {
          color: 'bg-red-500',
          text: this.$t('CHATLYN_CREDENTIALS.STATUS.EXPIRED'),
        },
        broken: {
          color: 'bg-gray-200',
          text: this.$t('CHATLYN_CREDENTIALS.STATUS.BROKEN'),
        },
      };
    },
  },
  methods: {
    openDeleteConfirmation(credId) {
      this.showDeleteConfirmation = true;
      this.selectedCredential = credId;
    },
    closeDeleteConfirmation() {
      this.showDeleteConfirmation = false;
    },
    async onConfirmDeletion() {
      await this.deleteCredential(this.selectedCredential);
      this.showDeleteConfirmation = false;
      if (this.$route.params?.credentialId === this.selectedCredential) {
        this.redirectToList();
      }
      this.selectedCredential = '';
    },
    async actionsOnSuccess(url, isUpdate = false) {
      if (url) {
        this.redirectToAuth(url);
      } else {
        await this.redirectAndRefetch();
      }
      const successMessage = isUpdate
        ? this.$t('CHATLYN_CREDENTIALS.NOTIFICATIONS.UPDATE_SUCCESS')
        : this.$t('CHATLYN_CREDENTIALS.NOTIFICATIONS.CREATE_SUCCESS');
      const alert = url
        ? this.$t('CHATLYN_CREDENTIALS.NOTIFICATIONS.AUTHORIZE')
        : successMessage;
      this.showAlert(alert);
    },
    redirectToEditPage(id) {
      const url = this.addAccountScoping(`settings/credentials/${id}`);
      this.$router.push(url);
    },
    redirectToList() {
      const url = this.addAccountScoping(`settings/credentials/list`);
      this.$router.push(url);
    },
    redirectToAuth(externalURL) {
      const url = this.addAccountScoping(`settings/credentials/authorizing`);
      this.$router.replace(url);
      window.open(externalURL, '', 'width=500, height=500 left=500 top=200');
    },
    async getCredential(credId) {
      await this.$store.dispatch('credentials/getCredential', {
        accountId: this.accountId,
        credId,
      });
    },
    async getCredentials() {
      await this.$store.dispatch('credentials/getAllCredentials', {
        accountId: this.accountId,
      });
    },
    async createCredential(credential) {
      try {
        const resp = await this.$store.dispatch(
          'credentials/createCredential',
          {
            accountId: this.accountId,
            credential,
          }
        );
        await this.actionsOnSuccess(resp?.data);
      } catch (err) {
        this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'));
      }
    },
    async updateCredential(credential, credId) {
      try {
        const resp = await this.$store.dispatch(
          'credentials/updateCredential',
          {
            accountId: this.accountId,
            credential,
            credId,
          }
        );
        await this.actionsOnSuccess(resp?.data, true);
      } catch (err) {
        this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'));
      }
    },
    async deleteCredential(credId) {
      await this.$store
        .dispatch('credentials/deleteCredential', {
          accountId: this.accountId,
          credId,
        })
        .then(() => {
          this.showAlert(
            this.$t('CHATLYN_CREDENTIALS.NOTIFICATIONS.DELETE_SUCCESS')
          );
        })
        .catch(() => {
          this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN'));
        });
    },
    async redirectAndRefetch() {
      this.redirectToList();
      await this.getCredentials();
    },
  },
};
