<template>
  <formulate-form v-slot="{ hasErrors }" class="column content-box">
    <woot-modal-header
      :header-title="
        isContacts
          ? $t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TITLE')
          : $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.TITLE', {
              TEMPLATE_NAME: messageTemplate.title,
            })
      "
    />

    <div class="modal-content send-message-template-modal-body">
      <div
        v-if="!isAllTranslationsApproved"
        class="callout warning mb-3 flex items-center gap-2 p-3"
      >
        <fluent-icon class="flex-shrink-0" icon="warning" :size="16" />
        <span>
          {{
            $t(
              'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.NOT_ALL_TRANSLATIONS_APPROVED_MESSAGE'
            )
          }}
        </span>
      </div>

      <div
        v-if="isShowWarning"
        class="message-template-10-minutes-warning-message callout alert mb-3 flex items-start gap-2 p-3"
      >
        <fluent-icon class="mt-1 flex-shrink-0" icon="warning" :size="16" />
        <div class="message-template-10-minutes-warning-message__content">
          <div class="text-base font-semibold">
            {{
              $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.10_MINUTES_WARNING.TITLE')
            }}
          </div>
          <div class="text-sm">
            {{
              $t(
                'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.10_MINUTES_WARNING.MESSAGE'
              )
            }}
          </div>
        </div>
      </div>

      <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-5">
        <div class="col-span-1 flex flex-col gap-3 lg:col-span-3">
          <template v-if="isContacts">
            <multiselect-ch
              v-model="template"
              :title="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TEMPLATE')"
              :options="availableMessageTemplates"
              :multiple="false"
              label="title"
              :max-height="140"
            >
              <template #title>
                <span class="mr-1 flex items-center">
                  {{ $t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TEMPLATE') }}
                </span>

                <fluent-icon
                  v-tooltip.top="
                    $t(
                      'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TEMPLATE_TOOLTIP'
                    )
                  "
                  icon="info"
                  size="16"
                />
              </template>
            </multiselect-ch>
          </template>

          <template v-else>
            <multiselect-ch
              v-model="contactList"
              :title="$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_LIST')"
              :options="contactLists"
              :multiple="false"
              label="name"
              :max-height="200"
            >
              <template #title>
                <label class="mr-1 flex items-center">
                  {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_LIST') }}
                </label>
              </template>
            </multiselect-ch>
          </template>

          <div
            v-if="!hasLinks"
            class="message-template-scheduled-container"
            style="gap: 0; margintop: 0"
          >
            <div class="flex flex-row flex-nowrap items-center gap-2">
              <span>
                {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.RECIPIENTS') }}
              </span>

              <fluent-icon
                v-if="contactList && contactList.name"
                v-tooltip.top="
                  $t(
                    'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.RECIPIENTS_TOOLTIP',
                    { list: contactList.name }
                  )
                "
                icon="info"
                size="16"
              />
            </div>

            <div class="text-4xl font-light dark:text-white">
              {{ contactsCount }}
            </div>

            <send-message-limit-warning
              v-if="isSendMessageLimitShown"
              class="mt-0.5"
              :current-sending-limits="currentSendingLimits"
            />
          </div>

          <div class="message-template-scheduled-container !gap-0">
            <div class="message-template-scheduled-switcher">
              <woot-switch
                id="message-template-scheduled-switcher"
                v-model="showSchedule"
                :label="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.SCHEDULE')"
              />
            </div>

            <slide-animation :is-shown="showSchedule">
              <div
                v-if="showSchedule"
                class="schedule mt-3 flex w-full flex-wrap gap-x-4 gap-y-2"
              >
                <FormulateInput
                  v-model="scheduleDate"
                  class="flex-1"
                  type="date"
                  :label="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.DATE')"
                  placeholder="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.DATE')"
                  :min="minDate"
                  :validation="
                    minDate ? `required|after:${afterDate}` : 'required'
                  "
                />

                <FormulateInput
                  v-model="scheduleTime"
                  class="flex-1"
                  type="time"
                  :label="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIME')"
                  placeholder="$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIME')"
                  :validation="
                    minTime ? `required|timeAfter:${minTime}` : 'required'
                  "
                  :validation-messages="{
                    timeAfter: $t(
                      'CHATLYN_GENERAL.MESSAGES.AFTER_TIME_INVALID',
                      {
                        time: minTime,
                      }
                    ),
                  }"
                  :validation-rules="{
                    timeAfter: (event) => timeAfter(event, minTime),
                  }"
                  :min="minTime"
                />
                <div class="txt-muted w-full text-xs">{{ timezoneTitle }}</div>
              </div>
            </slide-animation>
          </div>

          <template v-if="template && template.translations && hasLinks">
            <div class="message-template-links-message callout warning">
              <span class="whitespace-nowrap p-0.5">{ }</span>
              <span>
                {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.LINKS_MESSAGE') }}
              </span>
            </div>

            <div
              v-if="template && template.translations"
              class="message-template-scheduled-container pt-3"
            >
              <div
                v-show="template.translations.length > 1"
                class="rounded-lg p-3 transition-colors duration-200"
                :class="{
                  'bg-woot-50 dark:bg-woot-800': isTranslationsAttributesSynced,
                  'bg-gray-50 dark:bg-gray-700':
                    !isTranslationsAttributesSynced,
                }"
              >
                <woot-switch
                  :id="`message-template-link-sync-${currentLanguage}-switch`"
                  v-model="isTranslationsAttributesSynced"
                  class="message-template-link-sync-switch"
                  :label="
                    $t(
                      'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SYNC_TRANSLATION_ATTRIBUTES'
                    )
                  "
                />
              </div>

              <language-bar
                has-color-mark
                :has-additional-text="false"
                :languages="languages"
                :translations="template.translations"
                :current-language="currentLanguage"
                @change="selectCurrentLanguage"
              />

              <template v-for="translation in template.translations">
                <links-before-send-editor
                  v-if="
                    translation.status === 'approved' &&
                    getTranslationLinks(translation).links.length &&
                    hasLinks
                  "
                  v-show="translation.language === currentLanguage"
                  :key="translation.language"
                  :new-links="newLinks"
                  :language-links="getTranslationLinks(translation)"
                  :buttons-component="
                    getTranslationButtonsComponents(translation)
                  "
                  :is-translations-attributes-synced="
                    isTranslationsAttributesSynced
                  "
                  :current-language="currentLanguage"
                  @change-new-links="changeNewLinks"
                  @change-language-new-links="changeLanguageNewLinks"
                />

                <div
                  v-else
                  v-show="translation.language === currentLanguage"
                  :key="translation.language"
                  class="message-template-links-message"
                >
                  <span> <fluent-icon icon="warning" :size="16" /> </span>

                  <span>
                    {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.NO_LINKS') }}
                  </span>
                </div>
              </template>
            </div>
          </template>
        </div>

        <div class="col-span-1 flex flex-col gap-3 lg:col-span-2">
          <div v-if="hasLinks">
            <div class="flex flex-row flex-nowrap items-center gap-1">
              <label>
                {{ $t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.RECIPIENTS') }}
              </label>

              <fluent-icon
                v-if="contactList && contactList.name"
                v-tooltip.top="
                  $t(
                    'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.RECIPIENTS_TOOLTIP',
                    { list: contactList.name }
                  )
                "
                icon="info"
                size="12"
              />
            </div>

            <div class="text-4xl font-light dark:text-white">
              {{ contactsCount }}
            </div>

            <send-message-limit-warning
              v-if="isSendMessageLimitShown"
              class="mt-0.5"
              :current-sending-limits="currentSendingLimits"
            />
          </div>

          <div class="message-template-number-container">
            <div class="flex items-center gap-1">
              <label>
                {{
                  $t(
                    'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_WHATSAPP_INBOX'
                  )
                }}
              </label>

              <fluent-icon
                v-tooltip.top="
                  $t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.NUMBER_TOOLTIP')
                "
                icon="info"
                size="12"
              />
            </div>
            <input
              type="text"
              disabled
              :value="`${selectedWhatsAppInbox.name} (${selectedWhatsAppInboxPhoneNumber})`"
            />
          </div>

          <div>
            <label>
              {{ $t('CHATLYN_MESSAGE_TEMPLATE.MESSAGE_PREVIEW') }}
            </label>
            <language-bar
              class="mb-2"
              has-color-mark
              :has-additional-text="false"
              :languages="languages"
              :translations="template.translations"
              :current-language="currentLanguage"
              @change="
                (language) => {
                  if (!hasLinks) {
                    selectCurrentLanguage(language);
                  }
                }
              "
            />

            <message-preview
              v-if="messageTemplateTranslation"
              :has-label="false"
              :current-translation="messageTemplateTranslation"
              :modal-mode="false"
              :custom-header-attachment="customFile"
              :empty-text="
                $t('CHATLYN_MESSAGE_TEMPLATE.SELECT_TEMPLATE_TO_PREVIEW')
              "
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-2 px-8 pb-8 pt-0">
      <woot-button
        color-scheme="success"
        :disabled="isSubmitButtonDisabled || hasErrors"
        :icon="showSchedule ? 'send-clock' : 'send'"
        @click.prevent="sendMessage"
      >
        {{
          $t(
            showSchedule
              ? 'CHATLYN_GENERAL.BUTTON.SEND_SCHEDULED'
              : 'CHATLYN_GENERAL.BUTTON.SEND'
          )
        }}
      </woot-button>
    </div>

    <div
      v-if="isMessageTemplateLoading"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </formulate-form>
</template>

<script>
import { format, getDate, getMinutes, setDate, setMinutes } from 'date-fns';
import { mapGetters } from 'vuex';
import MessagePreview from './MessagePreview';
import alertMixin from 'shared/mixins/alertMixin';
import MultiselectCh from '../../../../../shared/components/ui/Multiselect';
import { timeAfter } from '../../../../helper/formulateValidation';
import WootSwitch from 'shared/components/ui/Switch';
import getUuid from 'widget/helpers/uuid';
import WhatsAppAPI from '../../../../api/whatsApp';

import LinksBeforeSendEditor from './LinksBeforeSendEditor.vue';
import LanguageBar from './LanguageBar.vue';
import { timeZoneOptions } from '../../settings/inbox/helpers/businessHour';
import SlideAnimation from '../../../../components/animations/SlideAnimation.vue';
import SendMessageLimitWarning from './SendMessageLimitWarning.vue';

export default {
  name: 'SendMessageTemplateModal',
  components: {
    MessagePreview,
    MultiselectCh,
    WootSwitch,
    LinksBeforeSendEditor,
    LanguageBar,
    SlideAnimation,
    SendMessageLimitWarning,
  },
  mixins: [alertMixin],
  props: {
    isContacts: {
      type: Boolean,
      default: false,
    },

    messageTemplate: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      updateTineInterval: null,
      show: true,
      showSchedule: false,
      scheduleDate: null,
      scheduleTime: null,
      whatsAppInbox: null,
      contactList: {},
      template: {},
      currentDate: Date.now(),
      newLinks: [],
      currentLanguage: '',
      isTranslationsAttributesSynced: true,
      currentSendingLimits: 0,
    };
  },
  computed: {
    ...mapGetters({
      managementAccount: 'management/getAccount',
      contactLists: 'contactLists/getAvailableLists',
      currentAccountId: 'getCurrentAccountId',
      contactListMeta: 'contactLists/getMeta',
      messageTemplates: 'messageTemplates/getApprovedMessageTemplates',
      isMessageTemplateLoading: 'messageTemplates/getIsMessageTemplateLoading',
      whatsAppInboxes: 'inboxes/getWhatsAppInboxes',
      currentContactList: 'contactLists/getList',
    }),

    languages() {
      return this.template?.translations?.map((translation) => ({
        name: translation.language,
      }));
    },

    isShowWarning() {
      if (
        !Array.isArray(this.template.translations) ||
        !this.template.translations.length
      ) {
        return false;
      }

      const templateApprovedDate = new Date(
        Math.max(
          ...this.template.translations.map(
            (translation) =>
              (['approved', 'APPROVED'].includes(translation.status)
                ? new Date(
                    translation.statusUpdatedAt || translation.updatedAt
                  ).getTime()
                : -1) || -1
          )
        )
      );

      const currentDate = new Date();

      return (
        setMinutes(currentDate, getMinutes(currentDate) - 10).getTime() <=
        new Date(templateApprovedDate).getTime()
      );
    },

    isSubmitButtonDisabled() {
      return this.isContacts
        ? !Object.keys(this.template).length
        : !Object.keys(this.contactList).length;
    },

    contactsCount() {
      if (this.isContacts) {
        return this.contactListMeta?.itemCount;
      }

      if (!this.contactList || Object.keys(this.contactList).length <= 0) {
        return '--';
      }

      return this.contactList.count;
    },

    messageTemplateTranslation() {
      if (!this.template || !this.template.translations) {
        return {};
      }

      return this.template.translations.find(
        (translation) => translation.language === this.currentLanguage
      );
    },

    timezoneTitle() {
      const matchedTimeZone =
        timeZoneOptions()?.find(
          (timeZone) => timeZone.value === this.managementAccount?.timezone
        )?.label ||
        `${format(new Date(), 'z')}
        (${Intl.DateTimeFormat().resolvedOptions().timeZone})`;

      return `${this.$t(
        'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIMEZONE'
      )} ${matchedTimeZone}`;
    },

    minDate() {
      return format(this.currentDate, 'yyyy-MM-dd');
    },

    afterDate() {
      const date = new Date(this.currentDate);

      return format(setDate(date, getDate(date) - 1), 'yyyy-MM-dd');
    },

    minTime() {
      if (!this.scheduleDate) {
        return undefined;
      }

      if (format(this.currentDate, 'yyyy-MM-dd') !== this.scheduleDate) {
        return undefined;
      }

      const now = Date.now();

      return format(setMinutes(now, getMinutes(now) + 1), 'HH:mm');
    },

    selectedWhatsAppInbox() {
      return this.whatsAppInbox
        ? this.whatsAppInboxes.find(
            // eslint-disable-next-line eqeqeq
            (whatsAppInbox) => whatsAppInbox.id == this.whatsAppInbox
          )
        : this.whatsAppInboxes[0];
    },

    linksValueTypeOptions() {
      return ['ATTRIBUTES', 'STATIC_TEXT'].map((key) => ({
        label: this.$t(`CHATLYN_MESSAGE_TEMPLATE.ATTRIBUTES.${key}`),
        value: key.toLowerCase(),
      }));
    },

    buttonsComponent() {
      return this.messageTemplateTranslation.components.find(
        (component) => component.type === 'BUTTONS'
      );
    },

    headerComponent() {
      return this.messageTemplateTranslation?.components?.find(
        (component) => component.type === 'HEADER'
      );
    },

    customFile() {
      return (
        this.currentLanguageLinks?.links?.find((link) =>
          ['image', 'video'].includes(link.valueType)
        )?.value || ''
      );
    },

    availableMessageTemplates() {
      return this.messageTemplates.filter(
        (template) => template.category !== 'CONSENT'
      );
    },

    currentLanguageLinks: {
      get() {
        return this.newLinks.find(
          (newLinks) => newLinks.language === this.currentLanguage
        );
      },

      set(newValue) {
        let newLinks = JSON.parse(JSON.stringify(this.newLinks));

        const index = newLinks.findIndex(
          (newLink) => newLink.language === newValue.language
        );

        if (index !== -1) {
          newLinks[index] = newValue;
        }

        this.newLinks = newLinks;
      },
    },

    hasLinks() {
      if (!this.newLinks?.length) {
        return false;
      }

      return this.newLinks.some((links) => links.links.length);
    },

    isAllTranslationsApproved() {
      return !this.template?.translations?.some(
        (translation) => translation.status !== 'approved'
      );
    },

    selectedWhatsAppInboxPhoneNumber() {
      return (
        this.selectedWhatsAppInbox.phone_number ||
        `+${this.selectedWhatsAppInbox?.additional_attributes?.number}`
      );
    },

    isSendMessageLimitShown() {
      if (typeof this.contactsCount !== 'number') {
        return false;
      }

      return (
        this.selectedWhatsAppInbox &&
        this.selectedWhatsAppInboxPhoneNumber &&
        this.contactsCount > this.currentSendingLimits
      );
    },
  },
  watch: {
    messageTemplate: {
      handler(newValue) {
        if (!newValue || !Object.keys(newValue).length) {
          return;
        }

        this.template = JSON.parse(JSON.stringify(newValue));
      },
      deep: true,
      immediate: true,
    },

    showSchedule: {
      handler(newValue) {
        if (newValue) {
          clearInterval(this.updateTineInterval);

          this.updateTineInterval = setInterval(() => {
            this.currentDate = Date.now();
          }, 1000 * 60);
        } else {
          clearInterval(this.updateTineInterval);
        }
      },
    },

    whatsAppInboxes: {
      handler(newValue) {
        if (!newValue?.length) {
          return;
        }

        this.setupMessageTemplateWhatsAppInbox();
      },
      immediate: true,
      deep: true,
    },

    'template._id': {
      handler(newValue) {
        if (!newValue) {
          return;
        }

        this.setupSelectedLinksValueTypes();

        if (this.template.translations.length) {
          this.currentLanguage = this.template.translations[0].language;
        }

        this.setupMessageTemplateWhatsAppInbox();
      },
      immediate: true,
    },

    selectedWhatsAppInboxPhoneNumber: {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        await this.getWhatsappSendingLimits();
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchManagementAccount();
    this.$store.dispatch('inboxes/get');

    if (this.isContacts) {
      this.contactList = this.currentContactList;
      this.fetchTemplates();
    } else {
      this.fetchContactLists();
    }
  },
  beforeDestroy() {
    clearInterval(this.updateTineInterval);
  },
  methods: {
    timeAfter,

    selectCurrentLanguage(newValue) {
      this.currentLanguage = newValue;
    },

    onClose() {
      this.$emit('close');
    },

    getTranslationButtonsComponents(translation) {
      return translation?.components?.find(
        (component) => component.type === 'BUTTONS'
      );
    },

    getTranslationLinks(translation) {
      return this.newLinks.find(
        (newLinks) => newLinks.language === translation.language
      );
    },

    async fetchTemplates() {
      await this.$store.dispatch('messageTemplates/getAllMessageTemplates', {
        accountId: this.currentAccountId,
      });
    },

    async fetchContactLists() {
      await this.$store.dispatch('contactLists/getLists', {
        accountId: this.$route.params.accountId,
      });
    },

    changeNewLinks(newValue) {
      this.newLinks = structuredClone(newValue);
    },

    changeLanguageNewLinks(newValue) {
      this.currentLanguageLinks = structuredClone(newValue);
    },

    async sendMessage() {
      const links = this.template.translations.map((translation) => {
        const matchedLinks = this.newLinks.find(
          (newLink) => translation.language === newLink.language
        );

        const linksToSend = {
          language: translation.language,
          body: [],
          buttons: [],
          header: [],
        };

        ['header', 'body', 'buttons'].forEach((key) => {
          [...matchedLinks.links].forEach((newLink) => {
            if (newLink.linkType === key) {
              if (
                ['image', 'video'].includes(newLink.valueType) &&
                !newLink.value
              ) {
                newLink.value = newLink.example;
              }

              delete newLink.linkType;
              delete newLink.valueType;
              delete newLink.id;

              linksToSend[key].push(newLink);
            }
          });
        });

        return linksToSend;
      });

      await this.$store.dispatch('messages/sendMessage', {
        data: {
          clientId: this.currentAccountId,
          // eslint-disable-next-line no-underscore-dangle
          templateId: this.template._id,
          // eslint-disable-next-line no-underscore-dangle
          listId: this.contactList._id || this.contactList.id,
          date: this.showSchedule
            ? new Date(`${this.scheduleDate} ${this.scheduleTime}`)
            : new Date(),
          number: this.selectedWhatsAppInboxPhoneNumber.replace(/\+/g, ''),
          links: links,
        },
      });

      this.showAlert(this.$t('CHATLYN_GENERAL.MESSAGES.SUCCESS'));
      this.onClose();
    },

    setupSelectedLinksValueTypes() {
      // Change it if we enable multi language templates

      const newLinks = this.template.translations.map((translation) => {
        const newLanguageLinks = [];

        const links = translation.links
          ? JSON.parse(JSON.stringify(translation.links))
          : {
              header: [],
              body: [],
              buttons: [],
            };

        const editableHeaderFormats = ['image', 'video'];

        const headerComponent = translation.components?.find(
          (component) => component.type === 'HEADER'
        );

        if (
          headerComponent &&
          editableHeaderFormats.includes(headerComponent.format.toLowerCase())
        ) {
          links.header.push({
            example: headerComponent.example.header_handle[0],
            number: 1,
            value: '',
            valueType: headerComponent.format.toLowerCase(),
          });
        }

        ['header', 'body', 'buttons'].forEach((key) => {
          links[key].forEach((link) => {
            const newLink = {
              ...link,
              linkType: key,
              id: getUuid(),
            };

            if (!newLink.valueType) {
              newLink.valueType = link.value
                ? this.linksValueTypeOptions[0].value
                : this.linksValueTypeOptions[1].value;
            }

            newLanguageLinks.push(newLink);
          });
        });

        return {
          language: translation.language,
          links: newLanguageLinks,
        };
      });

      this.newLinks = newLinks;
    },

    setupMessageTemplateWhatsAppInbox() {
      if (
        !this.template ||
        !Object.keys(this.template).length ||
        !this.whatsAppInboxes?.length
      ) {
        return;
      }

      const matchedWhatsAppInbox =
        this.whatsAppInboxes.find(
          (whatsAppInbox) =>
            whatsAppInbox?.phone_number?.replace(/\+/g, '') ===
              this.template.phoneNumber ||
            whatsAppInbox?.additional_attributes?.number ===
              this.template.phoneNumber
        ) || this.whatsAppInboxes[0];

      this.whatsAppInbox = matchedWhatsAppInbox.id;
    },

    async fetchManagementAccount() {
      await this.$store.dispatch('management/getAccount', {
        accountId: this.currentAccountId,
      });
    },

    async getWhatsappSendingLimits() {
      try {
        const response = await WhatsAppAPI.getWhatsApp24hSendingLimits(
          this.selectedWhatsAppInboxPhoneNumber
        );

        this.currentSendingLimits = response.data;
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style lang="scss">
.message-template-links-message {
  @apply flex flex-row items-center gap-2 text-xs;
}

.send-message-template-modal-body {
  @apply flex w-full flex-col flex-nowrap;

  .formulate-input-label {
    @apply mb-2;
  }

  .message-template-scheduled-container {
    @apply flex flex-col flex-nowrap gap-3 rounded-md border p-3 dark:border-gray-700;
  }
}
</style>
