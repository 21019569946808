<template>
  <div>
    <div class="text-center shadow-lg bg-gray-900 dark:bg-gray-950 text-white rounded-md inline-flex my-3 py-2 px-4 max-w-md min-w-[15rem]">
      <div class="ui-snackbar-text w-full">
        {{ message }}
      </div>
      <div v-if="action">
        <router-link
          v-if="action.type == 'link'"
          :to="action.to"
          class="text-gray-200 underline underline-offset-1 cursor-pointer font-medium hover:text-white select-none"
        >
          {{ action.message }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    action: {
      type: Object,
      default: () => {},
    },
    showButton: Boolean,
    duration: {
      type: [String, Number],
      default: 3000,
    },
  },
  data() {
    return {
      toggleAfterTimeout: false,
    };
  },
};
</script>
