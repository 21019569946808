<template>
  <div
    :class="{ 'absolute-loader': absolute }"
    class="flex flex-col items-center justify-center p-8"
  >
    <spinner size="large" class="opacity-60 dark:opacity-70" />
    <div class="txt-muted mt-2 block text-center text-sm">{{ message }}</div>
  </div>
</template>
<script>
import Spinner from 'shared/components/Spinner.vue';
export default {
  components: { Spinner },
  props: {
    message: { type: String, default: '' },
    absolute: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.absolute-loader {
  @apply absolute z-10 h-full bg-modal;
}
</style>
