<template>
  <div :role="isFolder ? 'folder' : 'item'">
    <div v-if="isFolder">
      <woot-button
        class="mb-1 flex h-7 w-full max-w-full items-center overflow-hidden text-ellipsis whitespace-nowrap rounded px-2 transition-colors duration-200 hover:!bg-gray-100 dark:hover:!bg-gray-800"
        color-scheme="secondary"
        variant="clear"
        type="button"
        @click="toggleIsFolderExpanded"
      >
        <div
          class="group flex flex-row flex-nowrap items-center justify-between gap-2"
        >
          <span class="inline-flex">
            <fluent-icon
              class="opacity-70 transition-transform dark:opacity-50"
              :icon="isFolderExpanded || isOpened ? 'folder-open' : 'folder'"
              :size="14"
            />
          </span>

          <span class="flex-1 text-ellipsis">
            {{ item.label || item.name }}
          </span>

          <span
            v-if="hasMenuItems"
            class="h-4 w-4 opacity-0 group-focus-within:opacity-100 group-hover:opacity-100"
          >
            <popover-select
              v-model="selectedMenuOption"
              placement="bottom-end"
              :options="menuOptions.folder"
            >
              <button
                class="flex h-4 w-4 rounded"
                data-testid="dot-menu-toggler"
              >
                <fluent-icon class="h-full w-full" icon="more-horizontal" />
              </button>
            </popover-select>
          </span>
        </div>
      </woot-button>

      <div>
        <div class="pl-2.5">
          <slide-animation
            :is-shown="isFolderExpanded || isOpened"
            :duration="250"
          >
            <div v-if="isFolderHasChildren" class="mt-1">
              <template v-for="child in children">
                <div :key="child.id || child._id" class="grid w-full">
                  <slot
                    name="listItem"
                    :list-item="child"
                    :list-item-menu-options="menuOptions.item"
                  />
                </div>
              </template>
            </div>

            <div v-else class="grid w-full pb-2 pl-5 text-gray-500">
              {{ $t('FOLDERS.EMPTY_MESSAGE') }}
            </div>
          </slide-animation>
        </div>
      </div>
    </div>

    <div v-else>
      <slot
        name="listItem"
        :list-item="item"
        :list-item-menu-options="menuOptions.item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PopoverSelect from '../../../../shared/components/ui/PopoverSelect.vue';
import SlideAnimation from '../../animations/SlideAnimation.vue';

export default {
  name: 'FoldersListItem',
  components: { SlideAnimation, PopoverSelect },
  props: {
    item: {
      type: Object,
      required: true,
    },

    menuOptions: {
      type: Object,
      default: () => ({}),
    },

    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFolderExpanded: false,
    };
  },
  computed: {
    ...mapGetters({
      foldersState: 'folders/getFoldersState',
    }),

    isFolder() {
      return Array.isArray(this.item?.children);
    },

    children() {
      return this.item?.children || [];
    },

    isFolderHasChildren() {
      return !!this.children.length;
    },

    hasMenuItems() {
      return this.menuOptions?.folder?.length;
    },

    selectedMenuOption: {
      get() {
        return '';
      },

      async set(newValue) {
        const matchedOption = this.menuOptions.folder.find(
          (item) => item.value === newValue
        );

        if (!matchedOption || typeof matchedOption?.action !== 'function') {
          return;
        }

        // eslint-disable-next-line no-underscore-dangle
        matchedOption.action(this.item._id || this.item.id);
      },
    },
  },
  mounted() {
    this.setupIsFolderExpandedState();
  },
  methods: {
    toggleIsFolderExpanded() {
      this.isFolderExpanded = !this.isFolderExpanded;

      this.$store.dispatch('folders/setFoldersState', {
        state: {
          // eslint-disable-next-line no-underscore-dangle
          [`folder_${this.item._id}`]: this.isFolderExpanded,
        },
      });
    },

    setupIsFolderExpandedState() {
      // eslint-disable-next-line no-underscore-dangle
      const savedIsExpandedState = this.foldersState[`folder_${this.item._id}`];

      this.isFolderExpanded =
        typeof savedIsExpandedState === 'boolean'
          ? savedIsExpandedState
          : false;
    },
  },
};
</script>

<style></style>
