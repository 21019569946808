/* eslint-disable class-methods-use-this */
/* global axios */
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? 'https://staging.chatlyn.com/managementmw'
    : window.location.protocol +
      '//' +
      window.location.hostname +
      '/managementmw';

export const buildInboxOptOutsParams = (page, sort, limit, search) => {
  if (!page && !sort && !limit) {
    return '';
  }

  let query = '?';

  if (search) {
    query += `searchString=${search}&`;
  }

  if (page) {
    query += `page=${page}&`;
  }

  if (sort) {
    query += `order=${sort.toUpperCase()}&`;
  }

  if (limit) {
    query += `take=${limit}`;
  }

  if (query[query.length - 1] === '&') {
    query = query.slice(0, query.length - 1);
  }

  return query;
};

class ManagementAPI {
  getUserLanguage = async (userId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/user/${userId}/language`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  setUserLanguage = async (userId, language) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(
      `${url}/user/${userId}/language`,
      {
        language,
      },
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getAccount = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/account/${accountId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateAccount = async (accountId, account) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/account/${accountId}`, account, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getChatBubbles = (inboxId, lang) => {
    let link = `${url}/chatbubbles/bubbles/${inboxId}`;

    if (lang) {
      link += `/${lang}`;
    }

    return axios.get(link);
  };

  createChatBubbles = async (inboxId, accountId, chatBubbles) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(
      `${url}/chatbubbles/bubbles/${accountId}/${inboxId}`,
      chatBubbles,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  updateChatBubbles = async (inboxId, accountId, chatBubbles) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(
      `${url}/chatbubbles/bubbles/${accountId}/${inboxId}`,
      chatBubbles,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteChatBubble = async (inboxId, accountId, id) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(
      `${url}/chatbubbles/bubbles/${accountId}/${inboxId}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  deleteLanguageChatBubbles = async (inboxId, accountId, lang) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(
      `${url}/chatbubbles/bubbles/${accountId}/${inboxId}/lang/${lang}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  getSignatures = async (userId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/signatures/${userId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  createSignature = async (userId, accountId, signature) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/signatures/${userId}/${accountId}`, signature, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateSignature = async (userId, accountId, signature) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/signatures/${userId}/${accountId}`, signature, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  deleteSignature = async (userId, signatureId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.delete(`${url}/signatures/${userId}/${signatureId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getInboxOptOut = async (id) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/inbox/${id}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateInboxOptOut = async (inboxOptOut) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/inbox/update`, inboxOptOut, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getInboxOptOuts = async (
    inboxId,
    query = '',
    sort = 'asc',
    page = 1,
    limit = 10
  ) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(
      `${url}/inbox/${inboxId}/optouts/all${buildInboxOptOutsParams(
        page,
        sort,
        limit,
        query
      )}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );
  };

  updateInboxOptOuts = async (inboxId, optOuts) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.post(`${url}/inbox/${inboxId}/optout/update`, optOuts, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  getSms = async (accountId) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.get(`${url}/sms/${accountId}`, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };

  updateSms = async (accountId, sms) => {
    const jwtAuthTokens = await JwtAuth.getUserTokens();

    return axios.patch(`${url}/sms/${accountId}`, sms, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };
}

export default new ManagementAPI();
