<template>
  <div
    class="input-translator flex flex-col flex-nowrap"
    :class="{
      'w-max': !isExtended,
      'w-full': isExtended,
    }"
  >
    <div
      v-if="!isExtended"
      class="input-translator__toggler__container h-full w-max"
    >
      <woot-button
        size="small"
        color-scheme="secondary"
        variant="smooth"
        icon="translate"
        type="button"
        :class="{
          'active rounded-b-none': isContainerVisible,
        }"
        @click="toggleIsOpen"
      />
    </div>

    <slide-animation
      :class="{
        'absolute right-0 top-full w-full': !isExtended, //replace bottom with top
      }"
      :duration="200"
      :is-shown="isContainerVisible"
    >
      <div
        v-if="isContainerVisible"
        class="input-translator__container relative z-20 rounded-md bg-gray-300 p-2 shadow-lg dark:bg-gray-600"
        :class="{
          hidden: !isContainerVisible,
          '': isExtended,
          'rounded-tr-none': !isExtended,
        }"
      >
        <div
          class="input-translator__container__header flex flex-row flex-nowrap items-center gap-0.5 text-xs text-gray-500 dark:text-gray-400"
        >
          <span>{{ label || $t('CHATLYN_TRANSLATOR.AUTO_TRANSLATE') }}</span>

          <language-select
            v-model="selectedLanguage"
            :label="selectedDeeplLanguage.name || ''"
            :languages="deeplLanguagesOptions"
            @button-action="handleTranslateText"
          />
        </div>

        <div
          class="input-translator__container__body mt-0.5 flex flex-row flex-nowrap gap-2"
        >
          <input-counter
            class="w-full"
            is-not-input
            input-class="input-translator__container__body__translated-text"
            :value="translatedText || value"
            :max-length="maxLength"
            :is-hidden="!maxLength"
          >
            <div
              class="input-translator__container__body__translated-text flex h-full items-center border-none bg-transparent text-woot-800 outline-none dark:text-woot-50"
              style=""
            >
              <template v-if="translatedText || value">
                {{ translatedText || value }}
              </template>

              <span v-else class="text-gray-500 dark:text-gray-400">
                {{ $t('CHATLYN_TRANSLATOR.ERROR.NO_TEXT_PROVIDED') }}
              </span>
            </div>
          </input-counter>

          <woot-button
            class="mt-auto"
            color-scheme="secondary"
            variant="smooth"
            size="small"
            icon="insert-text"
            type="button"
            :is-disabled="isInsertButtonDisabled"
            @click="handleInsertButtonClick"
          >
            {{ $t('CHATLYN_GENERAL.BUTTON.INSERT') }}
          </woot-button>
        </div>

        <chatlyn-spinner :is-visible="isLoading" />
      </div>
    </slide-animation>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import {
  CH_I18N_TO_DEEPL_LANGUAGES_KEYS,
  insertSpacesBetweenEmoji,
} from './constants';
import DeeplAPI from '../../../../api/deepl';

import SlideAnimation from '../../../animations/SlideAnimation.vue';
import InputCounter from '../../../ui/InputCounter.vue';
import LanguageSelect from '../textTranslator/LanguageSelect.vue';
import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';

let translateTextInEditModeTimeout;

export default {
  name: 'InputTranslator',
  components: { SlideAnimation, InputCounter, LanguageSelect, ChatlynSpinner },
  mixins: [alertMixin],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },

    preferredLanguage: {
      type: String,
      default: 'en-US',
    },

    isExtended: {
      type: Boolean,
      default: false,
    },

    isHidden: {
      // Only for extended mode
      type: Boolean,
      default: true,
    },

    isAutoTranslateDisabled: {
      type: Boolean,
      default: false,
    },

    maxLength: {
      type: [Number, undefined],
      default: undefined,
    },

    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      selectedLanguage: '',
      isLoading: false,
      translatedText: '',
    };
  },
  computed: {
    ...mapGetters({
      deeplLanguages: 'deepl/getDeeplLanguages',
    }),

    selectedDeeplLanguage() {
      return (
        this.deeplLanguages.find(
          (deeplLanguage) => deeplLanguage.code === this.selectedLanguage
        ) || {}
      );
    },

    deeplLanguagesOptions() {
      return this.deeplLanguages.map((deeplLanguage) => {
        return {
          label: deeplLanguage.name,
          value: deeplLanguage.code,
        };
      });
    },

    isContainerVisible() {
      return this.isOpen || (this.isExtended && !this.isHidden);
    },

    isInsertButtonDisabled() {
      return (
        !this.translatedText.trim() ||
        (this.maxLength && this.translatedText.length > this.maxLength)
      );
    },
  },
  watch: {
    value: {
      handler() {
        if (this.isAutoTranslateDisabled) {
          return;
        }

        this.debounceTranslatingInEditMode();
      },
    },

    deeplLanguages: {
      handler() {
        this.setupPreferredLanguage();
      },
      deep: true,
    },

    preferredLanguage: {
      handler() {
        this.setupPreferredLanguage();
      },
      immediate: true,
    },

    selectedLanguage: {
      async handler(newValue) {
        if (!newValue || this.isAutoTranslateDisabled) {
          return;
        }

        await this.handleTranslateText();
      },
    },

    isContainerVisible: {
      async handler(newValue) {
        if (!newValue || this.isAutoTranslateDisabled) {
          return;
        }

        await this.handleTranslateText();
      },
    },
  },

  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },

    setupPreferredLanguage() {
      if (!this.preferredLanguage || !this.deeplLanguages?.length) {
        return;
      }

      this.selectedLanguage = (
        this.deeplLanguages.find(
          (deeplLanguage) =>
            deeplLanguage.code ===
              CH_I18N_TO_DEEPL_LANGUAGES_KEYS[this.preferredLanguage] ||
            deeplLanguage.code === this.preferredLanguage
        ) || { code: 'en-US' }
      ).code;
    },

    async handleTranslateText() {
      try {
        this.translatedText = '';

        if (
          !this.value?.trim() ||
          !this.selectedLanguage?.trim() ||
          !this.isContainerVisible
        ) {
          return;
        }

        this.isLoading = true;

        const response = await DeeplAPI.translateText(
          insertSpacesBetweenEmoji(this.value.trim()),
          this.selectedLanguage
        );

        this.translatedText = response.data.text?.trim();
      } catch (error) {
        this.showAlert(this.$t('CHATLYN_TRANSLATOR.ERROR.TRANSLATION'));
      } finally {
        this.isLoading = false;
      }
    },

    debounceTranslatingInEditMode() {
      clearTimeout(translateTextInEditModeTimeout);

      translateTextInEditModeTimeout = setTimeout(async () => {
        await this.handleTranslateText();
      }, 300);
    },

    handleInsertButtonClick() {
      if (!this.isExtended) {
        this.toggleIsOpen();
      }
      this.$emit('change', this.translatedText);
      this.$emit('action-after-translate');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-translator {
  .input-translator__container {
    .input-translator__container__body {
      .input-translator__container__body__translated-text {
        @apply pointer-events-none cursor-default text-sm;
        background: transparent !important;
        padding-left: 0 !important;
        word-break: break-word;
      }
    }
  }
}
</style>
