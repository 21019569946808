<template>
  <formulate-form
    v-slot="{ hasErrors }"
    class="google-business-account-inbox-container wizard-body h-auto flex-shrink-0 flex-grow-0 xl:pe-64"
    @submit="createInbox"
  >
    <page-header
      class="google-business-account-inbox-header"
      :header-title="$t('CHATLYN_INBOXES.GBM.SETUP.TITLE')"
      :header-content="$t('CHATLYN_INBOXES.GBM.SETUP.DESC')"
    />

    <div class="google-business-account-inbox-bod flex flex-col gap-3">
      <formulate-input
        v-model="inbox.name"
        :label="$t('CHATLYN_INBOXES.GBM.SETUP.INBOX_NAME')"
        validation="required"
        type="text"
      />

      <div class="google-business-account-inbox-private-key">
        <label>
          <div class="google-business-account-inbox-private-key-title">
            {{ $t('CHATLYN_INBOXES.GBM.SETUP.PRIVATE_KEY_CLIENT_EMAIL.TITLE') }}
          </div>

          <div class="txt-muted my-1 text-xs">
            {{ $t('CHATLYN_INBOXES.GBM.SETUP.PRIVATE_KEY_CLIENT_EMAIL.DESC') }}
          </div>
        </label>

        <drag-drop-file-upload
          v-model="file"
          :extensions="ALLOWED_FILE_EXTENSIONS"
        />

        <resizable-text-area
          :id="contentTextAreaId"
          v-model="formattedFileContent"
          is-formulate-form-component
          class="google-business-account-inbox-private-content mt-3"
          validation="required|jsonFormat"
          :validation-messages="{
            jsonFormat: $t('CHATLYN_GENERAL.MESSAGES.JSON_INVALID'),
          }"
          :validation-rules="{
            jsonFormat: (event) =>
              jsonFormat(event, ['private_key', 'client_email']),
          }"
          :min-height="7"
          :placeholder="$t('CHATLYN_GENERAL.PLACEHOLDER.PASTE_CONTENT')"
        />
      </div>

      <formulate-input
        v-model="inbox.brandId"
        :label="$t('CHATLYN_INBOXES.GBM.SETUP.BRAND_ID')"
        validation="required"
        type="text"
      />

      <formulate-input
        v-model="inbox.agentId"
        :label="$t('CHATLYN_INBOXES.GBM.SETUP.AGENT_ID')"
        validation="required"
        type="text"
      />

      <formulate-input
        v-model="inbox.partnerId"
        :label="$t('CHATLYN_INBOXES.GBM.SETUP.PARTNER_ID')"
        validation="required"
        type="text"
      />

      <formulate-input
        v-model="inbox.webhookToken"
        :label="$t('CHATLYN_INBOXES.GBM.SETUP.WEBHOOK_TOKEN')"
        validation="required"
        type="text"
      />
    </div>

    <div class="google-business-account-inbox-footer">
      <woot-button :disabled="hasErrors">
        {{ $t('CHATLYN_INBOXES.GBM.SETUP.CREATE_INBOX') }}
      </woot-button>
    </div>

    <div
      v-if="isCreating"
      class="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center"
      style="background: rgba(0, 0, 0, 0.05)"
    >
      <span class="spinner h-8 w-8" />
    </div>
  </formulate-form>
</template>

<script>
import PageHeader from '../../SettingsSubPageHeader.vue';
import DragDropFileUpload from '../../../../../components/ui/DragDropFileUpload.vue';
import { mapGetters } from 'vuex';
import ResizableTextArea from '../../../../../../shared/components/ResizableTextArea.vue';
import { jsonFormat } from '../../../../../helper/formulateValidation';
import getUuid from 'widget/helpers/uuid';
import GoogleBusinessMessagesAPI from '../../../../../api/googleBusinessMessages';
import router from '../../../../index';

const ALLOWED_FILE_EXTENSIONS = ['json'];

export default {
  name: 'GoogleBusinessAccount',
  components: { PageHeader, DragDropFileUpload, ResizableTextArea },
  data() {
    return {
      ALLOWED_FILE_EXTENSIONS,
      isCreating: false,
      file: null,
      fileContent: '',
      inbox: {
        webhookToken: '',
        clientEmail: '',
        privateKey: '',
        name: '',
        partnerId: '',
        brandId: '',
        agentId: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
    }),

    contentTextAreaId() {
      return getUuid();
    },

    formattedFileContent: {
      get() {
        try {
          const value = JSON.stringify(
            JSON.parse(this.fileContent),
            undefined,
            4
          );

          return value;
        } catch (error) {
          return this.fileContent;
        }
      },

      set(newValue) {
        this.fileContent = newValue;
      },
    },
  },
  watch: {
    file: {
      async handler(file) {
        this.formattedFileContent = await this.getFileContent(file);
      },
      deep: true,
    },
  },
  methods: {
    jsonFormat,

    async getFileContent(file) {
      if (!file || !file.name) {
        return Promise.resolve('');
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          try {
            resolve(event.target.result);
          } catch (error) {
            reject(error);
          }
        };

        reader.readAsText(file);
      });
    },

    async createInbox() {
      try {
        this.isCreating = true;

        const parsed = JSON.parse(this.fileContent);

        const { data: inbox } =
          await GoogleBusinessMessagesAPI.createGoogleBusinessMessagesInbox({
            ...this.inbox,
            accountId: this.currentAccountId,
            clientEmail: parsed.client_email,
            privateKey: parsed.private_key,
          });

        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_INBOXES.GBM.SETUP.SUCCESS_MESSAGE')
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: inbox.chatlynInboxId,
          },
        });
      } catch (error) {
        bus.$emit(
          'newToastMessage',
          this.$t('CHATLYN_GENERAL.MESSAGES.TRY_AGAIN')
        );
      } finally {
        this.isCreating = false;
      }
    },
  },
};
</script>

<style lang="scss">
.google-business-account-inbox-container {
  @apply flex flex-col flex-nowrap gap-4 overflow-y-auto overflow-x-hidden;

  .google-business-account-inbox-header {
    flex: 0 !important;
  }

  .google-business-account-inbox-body {
    .google-business-account-inbox-private-key {
      @apply flex flex-col flex-nowrap gap-4;

      label {
        @apply flex flex-col flex-nowrap gap-2;

        .google-business-account-inbox-private-key-title {
          @apply text-woot-800;
        }

        .google-business-account-inbox-private-key-description {
          @apply text-gray-500;
        }
      }

      .google-business-account-inbox-private-content {
        textarea {
          @apply rounded-lg border border-solid border-gray-200 bg-white;

          max-height: 14rem;
          min-height: 7rem;
          resize: none;
        }
      }
    }
  }
}
</style>
