var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuItemVisible),expression:"isMenuItemVisible"}],staticClass:"mt-1 select-none"},[(_vm.hasSubMenu)?_c('div',{staticClass:"block w-full"},[_c('woot-button',{staticClass:"mt-1 !h-8 w-full px-2 py-0 text-sm font-semibold text-gray-700",attrs:{"type":"button","color-scheme":"secondary","variant":"clear"},on:{"click":_vm.toggleIsSubMenuExpanded}},[(!_vm.showSearchField)?_c('div',{staticClass:"flex w-full flex-nowrap items-center justify-between gap-1"},[_c('fluent-icon',{staticClass:"opacity-70 transition-transform dark:opacity-50",class:{
            'rotate-90': _vm.isSubMenuExpanded,
          },attrs:{"icon":"chevron-right","size":12}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.customTranslation))]),_vm._v(" "),_c('span',{staticClass:"ml-auto flex flex-row flex-nowrap items-center gap-1",on:{"click":function($event){$event.preventDefault();}}},[(_vm.menuItem.hasSearch)?_c('button',{staticClass:"flex items-center p-1",on:{"click":_vm.toggleShowSearchField}},[_c('fluent-icon',{attrs:{"icon":"search","size":14}})],1):_vm._e(),_vm._v(" "),_c('span',{staticClass:"inline-flex"},[(_vm.hasAddMenu)?_c('popover-select',{attrs:{"placement":"bottom-end","options":_vm.menuItem.menuOptions.main},model:{value:(_vm.selectedAddOption),callback:function ($$v) {_vm.selectedAddOption=$$v},expression:"selectedAddOption"}},[_c('button',{staticClass:"flex items-center py-1"},[_c('fluent-icon',{attrs:{"icon":"add","size":16}})],1)]):(_vm.showItem(_vm.menuItem))?_c('router-link',{attrs:{"to":_vm.menuItem.toState,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('span',{staticClass:"-mt-1 pl-1",on:{"click":function($event){$event.stopPropagation();}}},[_c('a',{attrs:{"href":href}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      content: _vm.$t(`${_vm.menuItem.newLinkTag}`),
                      delay: { show: 500, hide: 100 },
                    }),expression:"{\n                      content: $t(`${menuItem.newLinkTag}`),\n                      delay: { show: 500, hide: 100 },\n                    }"}],attrs:{"data-testid":_vm.menuItem.dataTestid},on:{"click":(e) => _vm.newLinkClick(e, navigate)}},[_c('fluent-icon',{attrs:{"icon":"add","size":16}})],1)])])]}}],null,false,2027302977)}):_vm._e()],1)])],1):_c('div',{staticClass:"secondary-menu__header__search-field-container relative"},[(!_vm.showSearchField)?_c('fluent-icon',{staticClass:"transition-transform",class:{
            'rotate-90': _vm.isSubMenuExpanded,
          },attrs:{"icon":"chevron-right","size":16}}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"absolute left-0 flex items-center justify-center text-gray-400",style:({
            height: '1.4rem',
            width: '1.4rem',
          }),on:{"click":function($event){$event.stopPropagation();}}},[_c('fluent-icon',{staticClass:"absolute text-gray-400",attrs:{"icon":"search","size":14}}),_vm._v(" "),_c('chatlyn-spinner',{staticClass:"!h-full !w-full !bg-transparent",attrs:{"is-visible":_vm.isLoading || _vm.menuItem.isLoading}})],1),_vm._v(" "),_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[_c('woot-input',{ref:_vm.queryInputRef,staticClass:"secondary-menu__header__search-field-input",attrs:{"type":"text","placeholder":_vm.menuItem.searchInputPlaceholder,"autofocus":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_vm._v(" "),(_vm.menuItem.hasSearch)?_c('button',{staticClass:"absolute right-0 -mt-0.5",on:{"click":function($event){$event.stopPropagation();return _vm.toggleShowSearchField.apply(null, arguments)}}},[_c('fluent-icon',{attrs:{"icon":"dismiss","size":16}})],1):_vm._e()],1)]),_vm._v(" "),(_vm.menuItem.showNewButton)?_c('div',{staticClass:"flex items-center"},[_c('woot-button',{staticClass:"ml-2 p-0",attrs:{"size":"tiny","variant":"clear","color-scheme":"secondary","icon":"add"},on:{"click":_vm.onClickOpen}})],1):_vm._e()],1):_c('router-link',{staticClass:"m-0 flex items-center rounded p-2 text-sm leading-4 text-gray-700 transition-colors duration-200 hover:bg-gray-100 dark:text-gray-100 dark:hover:bg-gray-800",class:_vm.computedClass,attrs:{"to":_vm.menuItem && _vm.menuItem.toState}},[_c('fluent-icon',{staticClass:"mr-1.5 min-w-[1rem] rtl:ml-1.5 rtl:mr-0",attrs:{"icon":_vm.menuItem.icon,"size":"14"}}),_vm._v(" "),_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.$t(`${_vm.menuItem.label}`)),expression:"$t(`${menuItem.label}`)"}]}),_vm._v(" "),(_vm.showChildCount(_vm.menuItem.count))?_c('span',{staticClass:"mx-1 rounded-md px-1 py-0 text-xxs font-medium",class:{
        'text-gray-400 dark:text-gray-500': _vm.isCountZero && !_vm.isActiveView,
        'text-gray-600 dark:text-gray-100': !_vm.isCountZero && !_vm.isActiveView,
        'bg-woot-100 text-woot-600 dark:bg-woot-200 dark:text-woot-600':
          _vm.isActiveView,
        'bg-gray-100 dark:bg-gray-700': !_vm.isActiveView,
      }},[_vm._v("\n      "+_vm._s(`${_vm.menuItem.count}`)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.menuItem.beta)?_c('span',{staticClass:"mx-1 inline-block rounded-full border border-green-500 px-1 text-xxs font-semibold leading-4 text-green-600",attrs:{"data-view-component":"true","label":"Beta"}},[_vm._v("\n      "+_vm._s(_vm.$t('SIDEBAR.BETA'))+"\n    ")]):_vm._e()],1),_vm._v(" "),(_vm.hasSubMenu)?_c('slide-animation',{attrs:{"is-shown":_vm.isSubMenuExpanded || _vm.showSearchField}},[_c('div',{staticClass:"pl-2",class:{
        'pointer-events-none cursor-wait bg-gray-100 dark:bg-gray-800':
          _vm.isLoading || _vm.menuItem.isLoading,
      }},[(_vm.menuItem.hasFolders)?[_c('drag-drop-list',{staticClass:"secondary-menu__drag-drop-list mt-1",attrs:{"menu-options":_vm.menuItem.menuOptions,"drag-disabled":!!_vm.query,"is-opened":_vm.showSearchField},scopedSlots:_vm._u([{key:"listItem",fn:function({ listItem, listItemMenuOptions }){return [_c('secondary-child-nav-item',{key:listItem.routeParams.id,attrs:{"icon":listItem.static
                  ? 'text-bullet-list-ltr'
                  : 'text-dynamic-list-ltr',"item":listItem,"to":listItem.routeParams.toState,"label":listItem.routeParams.label,"label-color":listItem.routeParams.color,"should-truncate":listItem.routeParams.truncateLabel,"big-icon":listItem.routeParams.icon,"warning-icon":_vm.computedInboxErrorClass(listItem.routeParams),"show-child-count":_vm.showChildCount(listItem.routeParams.count),"child-item-count":listItem.routeParams.count,"menu-options":listItemMenuOptions}})]}}],null,false,2633911689),model:{value:(_vm.itemsComputed),callback:function ($$v) {_vm.itemsComputed=$$v},expression:"itemsComputed"}}),_vm._v(" "),_c('folders-list',{attrs:{"has-main-folder":"","label":this.$t('FOLDERS.ARCHIVED'),"list":_vm.archivedItems,"menu-options":_vm.menuItem.menuOptions.archived,"is-opened":_vm.showSearchField},scopedSlots:_vm._u([{key:"listItem",fn:function({ listItem, listItemMenuOptions }){return [_c('secondary-child-nav-item',{key:listItem.routeParams.id,attrs:{"icon":listItem.static
                  ? 'text-bullet-list-ltr'
                  : 'text-dynamic-list-ltr',"item":listItem,"to":listItem.routeParams.toState,"label":listItem.routeParams.label,"label-color":listItem.routeParams.color,"should-truncate":listItem.routeParams.truncateLabel,"big-icon":listItem.routeParams.icon,"warning-icon":_vm.computedInboxErrorClass(listItem.routeParams),"show-child-count":_vm.showChildCount(listItem.routeParams.count),"child-item-count":listItem.routeParams.count,"menu-options":listItemMenuOptions}})]}}],null,false,2633911689)})]:_c('ul',{staticClass:"mb-0 ml-0 grid list-none"},[_vm._l((_vm.menuItem.children),function(child){return _c('secondary-child-nav-item',{key:child.id,attrs:{"to":child.toState,"label":child.label,"label-color":child.color,"should-truncate":child.truncateLabel,"big-icon":child.icon,"icon":_vm.computedInboxClass(child),"warning-icon":_vm.computedInboxErrorClass(child),"show-child-count":_vm.showChildCount(child.count),"child-item-count":child.count}})}),_vm._v(" "),(!Boolean(_vm.menuItem.children.length))?_c('div',{staticClass:"py-1 text-center text-sm text-gray-400 dark:text-gray-600"},[_vm._v("\n          "+_vm._s(_vm.menuItem.emptyStateText)+"\n        ")]):_vm._e()],2)],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }