<template>
  <div
    class="group relative"
    @mouseenter="startListening"
    @mouseleave="stopListening"
  >
    <div
      role="button"
      class="flex w-full cursor-pointer gap-1 rounded pl-2 pt-3 transition-colors duration-200"
      @click="openConversation()"
    >
      <div class="text-primary relative mt-0.5 flex-shrink-0">
        <fluent-icon :icon="getNotificationIcon(notificationItem)" :size="24" />
        <div
          v-if="isUnread"
          class="absolute left-0 top-0 inline-flex h-2 w-2 animate-ping rounded bg-green-500 duration-500"
        />
        <div
          v-if="isUnread"
          class="brd-panel absolute left-0 top-0 inline-flex h-2 w-2 rounded border bg-green-500"
        />
      </div>

      <div
        class="w-full border-b border-gray-100 pb-3 pl-1 pr-2 group-hover:border-transparent dark:border-gray-700 dark:group-hover:border-transparent"
      >
        <div
          class="txt-primary w-2/3 truncate text-sm"
          :class="isUnread ? 'font-semibold' : ''"
        >
          {{ pushTitle }}
        </div>
        <div class="flex items-center gap-1">
          <chatlyn-time-ago
            class="txt-muted text-xs font-medium"
            :last-activity-timestamp="notificationItem.last_activity_at"
          />
          <Thumbnail
            v-if="assigneeMeta"
            v-tooltip="assigneeMeta.name"
            :src="assigneeMeta.thumbnail"
            :username="assigneeMeta.name"
            size="16px"
          />
          <PriorityFlag :priority="primaryActor.priority" />
          <!--<StatusIcon :status="primaryActor.status" />-->
          <InboxNameAndId :inbox="inbox" :conversation-id="primaryActor.id" />
          <div v-if="snoozedUntilTime" class="flex items-center">
            <span class="txt-warning text-xs font-semibold">
              {{ snoozedDisplayText }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute bottom-0 right-0 top-0 inline-flex h-full items-center rounded bg-gradient-to-l from-gray-100 pl-20 pr-3 opacity-0 group-hover:opacity-100 dark:from-gray-900/50"
    >
      <button
        type="button"
        class="button secondary smooth small flex items-center gap-1 font-medium"
        @click="markNotification(notificationItem)"
      >
        <fluent-icon v-if="isUnread" icon="checkmark-double" :size="14" />
        {{ buttonTitle }}
        <span
          class="surface-smooth brd txt-secondary rounded border px-1 text-xxs font-semibold"
        >
          E
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import PriorityFlag from 'dashboard/components/widgets/conversation/PriorityFlag.vue';
import StatusIcon from '../../inbox/components/StatusIcon.vue';
import InboxNameAndId from '../../inbox/components/InboxNameAndId.vue';
import InboxContextMenu from '../../inbox/components/InboxContextMenu.vue';
import Thumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import ChatlynTimeAgo from 'dashboard/components/ui/ChatlynTimeAgo.vue';
import { snoozedReopenTime } from 'dashboard/helper/snoozeHelpers';
import { NOTIFICATION_TYPE_ICON } from '../constants';
export default {
  components: {
    PriorityFlag,
    InboxContextMenu,
    StatusIcon,
    InboxNameAndId,
    Thumbnail,
    ChatlynTimeAgo,
  },
  mixins: [timeMixin, alertMixin],
  props: {
    notificationItem: {
      type: Object,
      default: () => {},
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isContextMenuOpen: false,
      contextMenuPosition: { x: null, y: null },
    };
  },
  computed: {
    primaryActor() {
      return this.notificationItem?.primary_actor;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](
        this.primaryActor.inbox_id
      );
    },
    isUnread() {
      return !this.notificationItem?.read_at;
    },
    meta() {
      return this.primaryActor?.meta;
    },
    assigneeMeta() {
      return this.meta?.assignee;
    },
    pushTitle() {
      return this.$t(
        `INBOX.TYPES.${this.notificationItem.notification_type.toUpperCase()}`
      );
    },
    alertReadTitle() {
      return this.$t('INBOX.ALERTS.MARK_AS_READ');
    },
    alertUnReadTitle() {
      return this.$t('INBOX.ALERTS.MARK_AS_UNREAD');
    },
    buttonTitle() {
      return this.isUnread
        ? this.$t('INBOX.MENU_ITEM.MARK_AS_READ')
        : this.$t('INBOX.MENU_ITEM.MARK_AS_UNREAD');
    },
    snoozedUntilTime() {
      const { snoozed_until: snoozedUntil } = this.notificationItem;
      return snoozedUntil;
    },
    snoozedDisplayText() {
      if (this.snoozedUntilTime) {
        return `${this.$t('INBOX.LIST.SNOOZED_UNTIL')} ${snoozedReopenTime(
          this.snoozedUntilTime
        )}`;
      }
      return '';
    },
  },
  unmounted() {
    this.closeContextMenu();
  },
  beforeDestroy() {
    // Remove the event listener if the component is destroyed (safety measure)
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  methods: {
    openConversation() {
      this.$emit('click');
    },
    markNotification(notification) {
      if (this.isUnread) {
        this.markNotificationAsRead(notification);
      } else {
        this.markNotificationAsUnRead(notification);
      }
    },
    markNotificationAsRead(notification) {
      const {
        id,
        primary_actor_id: primaryActorId,
        primary_actor_type: primaryActorType,
      } = notification;
      this.$store
        .dispatch('notifications/read', {
          id,
          primaryActorId,
          primaryActorType,
          unreadCount: this.meta.unreadCount,
        })
        .then(() => {
          this.showAlert(this.alertReadTitle);
          this.$emit('mark-as-read');
        });
    },
    markNotificationAsUnRead(notification) {
      const {
        id,
        primary_actor_id: primaryActorId,
        primary_actor_type: primaryActorType,
      } = notification;
      this.$store
        .dispatch('notifications/unread', {
          id,
          primaryActorId,
          primaryActorType,
          unreadCount: this.meta.unreadCount,
        })
        .then(() => {
          this.showAlert(this.alertUnReadTitle);
          this.$emit('mark-as-unread');
        });
    },
    getNotificationIcon(notificationItem) {
      return NOTIFICATION_TYPE_ICON[notificationItem.notification_type];
    },
    // Add keydown listener when the item is hovered
    startListening() {
      window.addEventListener('keydown', this.handleKeyPress);
    },
    // Remove keydown listener when the hover ends
    stopListening() {
      window.removeEventListener('keydown', this.handleKeyPress);
    },
    handleKeyPress(event) {
      if (event.code === 'KeyE') {
        this.markNotificationAsRead(this.notificationItem);
      }
    },
  },
};
</script>
