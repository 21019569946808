import { LocalStorage } from 'shared/helpers/localStorage';

const updateDataById = (data = {}, newFields = [], oldFields = []) => {
  const savedInvalidKeys = data?.invalidMergeFields || [];
  const invalidMergeFields = savedInvalidKeys.filter(
    (key) => !newFields.some((field) => field.key === key)
  );
  if (newFields.length < oldFields.length) {
    oldFields.forEach(({ key }) => {
      const wasDeleted = !newFields.find((item) => item.key === key);
      if (wasDeleted) {
        invalidMergeFields.push(key);
      }
    });
  }
  return { ...data, invalidMergeFields };
};

export const updateInvalidMergeFields = (
  newFields = [],
  oldFields = [],
  automationsId = []
) => {
  const automationStore = LocalStorage.get('automation') || {};
  automationsId.forEach((id) => {
    automationStore[id] = updateDataById(
      automationStore[id],
      newFields,
      oldFields
    );
  });
  LocalStorage.set('automation', automationStore);
};
