// import axios from 'axios';
import types from '../../mutation-types';
import DeeplAPI from '../../../api/deepl';
import { CH_I18N_TO_DEEPL_LANGUAGES_KEYS } from 'components/widgets/conversation/translator/constants';

export const actions = {
  getDeeplLanguages: async ({ commit, state }, { language }) => {
    try {
      if (state.uiFlags.isFetching) {
        return;
      }

      const targetLanguage =
        CH_I18N_TO_DEEPL_LANGUAGES_KEYS[language] || language || 'en-US';

      commit(types.SET_DEEPL_UI_FLAGS, { isFetching: true });
      const { data } = await DeeplAPI.getCountriesList(targetLanguage);

      if (!data.some((item) => item.code === 'ar')) {
        data.splice(0, 0, {
          code: 'ar',
          name: 'Arabic',
          supportsFormality: false,
        });
      }

      commit(types.SET_DEEPL_LANGUAGES, data || []);
    } catch (error) {
      //
    } finally {
      commit(types.SET_DEEPL_UI_FLAGS, { isFetching: false });
    }
  },

  setUiFlag: ({ commit }, newFlags) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, newFlags);
    } catch (error) {
      // TODO some action
    }
  },
};
