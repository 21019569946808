var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":"","active-class":"active"},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('li',{staticClass:"mb-1 flex h-7 select-none items-center rounded px-2 transition-colors duration-200 hover:bg-gray-100 dark:hover:bg-gray-800",class:{
      'bg-woot-50 hover:bg-woot-50 dark:bg-woot-800 hover:dark:bg-woot-800':
        isActive,
      'max-w-full overflow-hidden text-ellipsis whitespace-nowrap':
        _vm.shouldTruncate,
    },on:{"click":navigate}},[_c('a',{staticClass:"inline-flex w-full max-w-full items-center text-left",attrs:{"href":href}},[(_vm.bigIcon)?_c('span',{staticClass:"badge--icon",style:({ backgroundColor: 'transparent' })},[_c('fluent-icon',{attrs:{"icon":_vm.bigIcon,"size":"12"}})],1):(_vm.icon)?_c('span',{staticClass:"me-1 inline-flex w-4 items-center justify-center bg-transparent dark:bg-transparent"},[_c('fluent-icon',{staticClass:"text-xs text-gray-600 dark:text-gray-300",class:{
            'text-woot-600 dark:text-woot-400': isActive,
          },attrs:{"icon":_vm.icon,"size":"12"}})],1):_vm._e(),_vm._v(" "),(_vm.labelColor)?_c('span',{staticClass:"me-1 inline-flex aspect-square h-3 w-3 rounded bg-gray-100",style:({ backgroundColor: _vm.labelColor })}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"group relative flex w-full items-center justify-between overflow-hidden text-ellipsis whitespace-nowrap"},[_c('span',{staticClass:"text-sm text-gray-700 dark:text-gray-200",class:{
            'text-woot-600 dark:text-woot-400': isActive,
            'max-w-full overflow-hidden text-ellipsis whitespace-nowrap':
              _vm.shouldTruncate,
          },style:({ width: '90%' }),attrs:{"title":_vm.menuTitle},on:{"~mouseenter":function($event){return _vm.handleTruncatedEnter.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.label)+"\n        ")]),_vm._v(" "),(_vm.showChildCount)?_c('span',{staticClass:"ms-1 rounded bg-gray-50 px-1 py-0 text-xxs font-medium opacity-100 group-focus-within:opacity-0 group-hover:opacity-0 dark:bg-gray-800",class:_vm.isCountZero
              ? `text-gray-400 dark:text-gray-400`
              : `text-gray-600 dark:text-gray-100`},[_vm._v("\n          "+_vm._s(_vm.childItemCount)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.menuOptions && _vm.menuOptions.length)?_c('span',{staticClass:"absolute right-0 opacity-0 group-focus-within:opacity-100 group-hover:opacity-100"},[_c('popover-select',{staticClass:"secondary-child-nav-item__popover",attrs:{"placement":"bottom-end","options":_vm.menuOptions},model:{value:(_vm.selectedMenuOption),callback:function ($$v) {_vm.selectedMenuOption=$$v},expression:"selectedMenuOption"}},[_c('button',{staticClass:"flex h-4 w-4 rounded",attrs:{"data-testid":"dot-menu-toggler"}},[_c('fluent-icon',{staticClass:"h-full w-full",attrs:{"icon":"more-horizontal"}})],1)])],1):_vm._e()]),_vm._v(" "),(_vm.warningIcon)?_c('span',{staticClass:"mr-1 inline-flex rounded-sm bg-gray-100"},[_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top-end",value:(_vm.$t('SIDEBAR.REAUTHORIZE')),expression:"$t('SIDEBAR.REAUTHORIZE')",modifiers:{"top-end":true}}],staticClass:"text-xxs text-red-500 dark:text-red-300",attrs:{"icon":_vm.warningIcon,"size":"12"}})],1):_vm._e()])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }