<template>
  <div
    class="card-wrapper rounded bg-white flex gap-8 justify-between p-4 items-center border dark:bg-gray-800 dark:border-gray-700 w-full"
  >
    <div class="flex-grow">
      <div class="flex gap-2 items-center">
        <span class="card-title">{{ title }}</span>
        <slot name="status" />
      </div>
      
      <div
        v-if="description || descriptionPairs.length"
        class="card-description mt-3 text-xs flex flex-col gap-3"
      >
        <span v-if="description">{{ description }}</span>
        <div v-if="descriptionPairs.length" class="flex gap-3">
          <div
            v-for="(pair, i) in descriptionPairs"
            :key="i"
            class="flex gap-2"
          >
            <span class="txt-muted">{{ pair.title }}</span>
            <span>{{ pair.value }}</span>
            <span v-if="descriptionPairs.length - 1 > i">·</span>
          </div>
        </div>
      </div>
    </div>
    <slot name="actions">
      <div class="flex gap-3">
        <woot-button
          v-tooltip.top="$t('LABEL_MGMT.FORM.EDIT')"
          color-scheme="secondary"
          icon="edit"
          size="small"
          variant="clear"
          @click="$emit('edit')"
        />
        <woot-button
          v-tooltip.top="$t('LABEL_MGMT.FORM.DELETE')"
          color-scheme="alert"
          icon="delete"
          size="small"
          variant="smooth"
          @click="$emit('delete')"
        />
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: '',
    },
    descriptionPairs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
