var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"flex h-full"},[_c('primary-sidebar',{attrs:{"logo-source":_vm.globalConfig.logoThumbnail,"installation-name":_vm.globalConfig.installationName,"is-a-custom-branded-instance":_vm.isACustomBrandedInstance,"account-id":_vm.accountId,"menu-items":_vm.primaryMenuItems,"active-menu-item":_vm.activePrimaryMenu.key},on:{"toggle-accounts":_vm.toggleAccountModal,"key-shortcut-modal":_vm.toggleKeyShortcutModal,"open-notification-panel":_vm.openNotificationPanel}}),_vm._v(" "),_c('div',{class:{
      'slide-in': _vm.isConversationActive
        ? _vm.isConversationSecondarySidebarShowing
        : _vm.showSecondarySidebar,
      'slide-out': _vm.isConversationActive
        ? !_vm.isConversationSecondarySidebarShowing
        : !_vm.showSecondarySidebar,
    }},[_c('secondary-sidebar',{class:_vm.sidebarClassName,attrs:{"account-id":_vm.accountId,"inboxes":_vm.inboxes,"labels":_vm.labels,"teams":_vm.teams,"custom-views":_vm.customViews,"menu-config":_vm.activeSecondaryMenu,"current-user":_vm.currentUser,"is-on-chatwoot-cloud":_vm.isOnChatwootCloud},on:{"add-label":_vm.showAddLabelPopup,"add-list":_vm.showAddListPopup,"add-entrypoint":_vm.showAddEntrypointPopup,"add-folder":_vm.showAddFolderPopup,"edit-list":_vm.showEditListPopup,"delete-list":_vm.showDeleteListPopup,"archive-item":_vm.showArchivePopup,"edit-folder":_vm.showEditFolderPopup,"delete-folder":_vm.showDeleteFolderPopup,"toggle-accounts":_vm.toggleAccountModal}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }