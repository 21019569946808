/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
import { MESSAGE_TEMPLATES_CHANNEL_TYPE } from './constants';

import MessageTemplates from './views/MessageTemplates';

const ScheduledMessageTemplates = () =>
  import('./views/ScheduledMessageTemplates');

export const routes = [
  {
    path: frontendURL('accounts/:accountId/message-templates/whatsapp'),
    name: 'whatsapp_message_templates',
    meta: {
      permissions: ['administrator'],
    },
    component: MessageTemplates,
    props: () => ({
      messageTemplateType: MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP,
    }),
  },
  {
    path: frontendURL('accounts/:accountId/message-templates/sms'),
    name: 'sms_message_templates',
    meta: {
      permissions: ['administrator'],
    },
    component: MessageTemplates,
    props: () => ({
      messageTemplateType: MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS,
    }),
  },
  // {
  //   path: frontendURL('accounts/:accountId/label/:label/message-templates'),
  //   name: 'label_all_message_templates',
  //   roles: ['administrator', 'agent'],
  //   component: MessageTemplates,
  //   props: (route) => ({ label: route.params.label }),
  // },
  {
    path: frontendURL('accounts/:accountId/scheduled-message-templates'),
    name: 'all_scheduled_message_templates',
    meta: {
      permissions: ['administrator', 'agents'],
    },
    component: ScheduledMessageTemplates,
    props: () => ({ isScheduled: true }),
  },
];
