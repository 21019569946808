// import axios from 'axios';
import types from '../../mutation-types';
import ChatbotAPI from '../../../api/chatbot';

// TODO Set right UI flags

export const actions = {
  setUiFlag: ({ commit }, newFlags) => {
    try {
      commit(types.SET_MESSAGE_TEMPLATE_UI_FLAGS, newFlags);
    } catch (error) {
      // TODO some action
    }
  },

  getHandover: async ({ commit }, { accountId, userId, botId, inboxId }) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, { isFetching: true });
      commit(types.CLEAR_CHATBOT_HANDOVER);

      const { data } = await ChatbotAPI.getHandover(
        accountId,
        userId,
        botId,
        inboxId
      );

      if (!data?.success || !data?.data) {
        throw new Error();
      }

      commit(types.SET_CHATBOT_HANDOVER, data.data);
    } catch (error) {
      result = false;
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, { isFetching: false });
    }

    return result;
  },

  updateHandover: async (
    { commit },
    { accountId, handover, updateStoreValue = false }
  ) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, { isUpdating: true });

      const { data } = await ChatbotAPI.updateHandover(accountId, handover);

      if (!data?.success || !data?.data) {
        throw new Error();
      }

      if (updateStoreValue) {
        commit(types.SET_CHATBOT_HANDOVER, data.data);
      }
    } catch (error) {
      result = false;
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, { isUpdating: false });
    }

    return result;
  },

  addHandoverTranslations: async (
    { commit },
    { accountId, handoverId, translations, updateStoreValue = false }
  ) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, { isTranslationsAdding: true });

      const { data } = await ChatbotAPI.addHandoverTranslations(
        accountId,
        handoverId,
        translations
      );

      if (!data?.success || !data?.data) {
        throw new Error();
      }

      if (updateStoreValue) {
        commit(types.SET_CHATBOT_HANDOVER, data.data);
      }
    } catch (error) {
      result = false;
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, { isTranslationsAdding: false });
    }

    return result;
  },

  deleteHandoverTranslation: async (
    { commit, rootGetters },
    { accountId, translationId, updateStoreValue }
  ) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, { isTranslationsDeleting: true });

      const { data } = await ChatbotAPI.deleteHandoverTranslation(
        accountId,
        translationId
      );

      if (!data?.success) {
        throw new Error();
      }

      if (updateStoreValue) {
        const newHandover = structuredClone(rootGetters['chatbot/getHandover']);

        commit(types.SET_CHATBOT_HANDOVER, {
          ...newHandover,
          translations: newHandover.translations.filter(
            (translation) => translation.id !== translationId
          ),
        });
      }
    } catch (error) {
      result = false;
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, { isTranslationsDeleting: false });
    }

    return result;
  },

  getConversationStatus: async (
    { commit, getters },
    { accountId, conversationId }
  ) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isConversationStatusFetching: true,
      });

      commit(types.CLEAT_CHATBOT_CONVERSATION_STATUS);

      const { data } = await ChatbotAPI.getConversationStatus(
        accountId,
        conversationId
      );

      commit(types.SET_CHATBOT_CONVERSATION_STATUS, data);

      commit(types.SET_CHATBOT_CONVERSATIONS_STATUSES_STATE, {
        [conversationId]: {
          ...getters.getConversationStatusState(conversationId),
          isAIEnabled: data.conversation_state === 'ai',
        },
      });
    } catch (error) {
      result = false;
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isConversationStatusFetching: false,
      });
    }

    return result;
  },

  updateConversationStatus: async (
    { commit, getters },
    { accountId, conversationId, state }
  ) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isConversationStatusUpdating: true,
      });
      // commit(types.CLEAT_CHATBOT_CONVERSATION_STATUS);
      const { data } = await ChatbotAPI.updateConversationStatus(
        accountId,
        conversationId,
        state
      );
      commit(types.SET_CHATBOT_CONVERSATION_STATUS, data);

      commit(types.SET_CHATBOT_CONVERSATIONS_STATUSES_STATE, {
        [conversationId]: {
          ...getters.getConversationStatusState(conversationId),
          isAIEnabled: data.conversation_state === 'ai',
        },
      });
    } catch (error) {
      result = false;
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isConversationStatusUpdating: false,
      });
    }

    return result;
  },

  getAINests: async ({ commit }, { accountId }) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isAINestLoading: true,
      });

      commit(types.CLEAR_CHATBOT_AI_NESTS);

      const { data } = await ChatbotAPI.getAINests(accountId);

      commit(types.SET_CHATBOT_AI_NESTS, data);
    } catch (error) {
      result = false;
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isAINestLoading: false,
      });
    }

    return result;
  },

  getAISuggestions: async (
    { commit },
    { nestId, accountId, message, guestIdentifier, answerLength }
  ) => {
    let result = true;

    try {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isAISuggestionLoading: true,
        isAISuggestionFailed: false,
      });

      commit(types.CLEAR_CHATBOT_AI_SUGGESTION);

      const { data } = await ChatbotAPI.getAISuggestions({
        nestId,
        accountId,
        message,
        guestIdentifier,
        answerLength,
      });

      commit(types.SET_CHATBOT_AI_SUGGESTION, data);
    } catch (error) {
      result = false;
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isAISuggestionFailed: true,
      });

      setTimeout(() => {
        commit(types.SET_CHATBOT_UI_FLAGS, {
          isAISuggestionFailed: false,
        });
      }, 5000);
    } finally {
      commit(types.SET_CHATBOT_UI_FLAGS, {
        isAISuggestionLoading: false,
      });
    }

    return result;
  },

  setConversationsStatuesState: ({ commit }, { state }) => {
    try {
      commit(types.SET_CHATBOT_CONVERSATIONS_STATUSES_STATE, state);
    } catch (error) {
      // TODO some action
    }
  },
};
