<template>
  <div class="message-preview-container" :class="messageTemplateClass">
    <label v-if="hasLabel" class="block">
      {{ $t('CHATLYN_MESSAGE_TEMPLATE.MESSAGE_PREVIEW') }}
    </label>

    <div
      v-if="!isPreviewEmpty"
      class="message-preview-background pointer-events-none flex items-end justify-start break-words rounded py-4 pe-12 ps-6"
    >
      <div
        class="message-preview-message relative block rounded-lg p-3"
        :style="{
          direction:
            currentTranslation && currentTranslation.language === 'ar'
              ? 'rtl'
              : '',
        }"
      >
        <div
          v-if="headerComponent"
          class="message-preview-message-header text-sm font-semibold"
        >
          <template v-if="headerComponent.format === 'TEXT'">
            <div v-show="headerComponent.text">
              {{ headerComponent.text }}
            </div>
          </template>

          <template
            v-if="
              (headerComponent.example &&
                headerComponent.example.header_handle) ||
              customHeaderAttachment
            "
          >
            <div
              v-if="headerComponent.format === 'IMAGE'"
              class="message-preview-message-header-media-container"
              :class="{
                'message-header--image':
                  !headerComponent.example.header_handle[0],
              }"
            >
              <img v-if="headerAttachment" :src="headerAttachment" />

              <div v-else class="message-header--preview" />
            </div>

            <div
              v-if="headerComponent.format === 'VIDEO'"
              class="message-preview-message-header-media-container message-header--video"
              :class="{
                'message-header--video':
                  !headerComponent.example.header_handle[0],
              }"
            >
              <video v-if="headerAttachment" :src="headerAttachment" controls />

              <div v-else class="message-header--preview" />
            </div>
          </template>
        </div>

        <template v-if="bodyComponent">
          <div
            v-show="bodyComponent.text"
            v-dompurify-html="formatTextWithLinks(bodyComponent.text)"
            class="message-preview-message-body"
          />
        </template>

        <template v-if="footerComponent">
          <div
            v-show="footerComponent.text"
            class="message-preview-message-footer"
          >
            {{ footerComponent.text }}
          </div>
        </template>
      </div>
      <div v-if="buttonsComponent" class="message-preview-message-buttons">
        <template v-for="(button, buttonIndex) in buttonsComponent.buttons">
          <button
            v-show="button.text"
            :key="buttonIndex"
            class="break-words"
            :style="{
              direction:
                currentTranslation && currentTranslation.language === 'ar'
                  ? 'rtl'
                  : '',
            }"
          >
            {{ button.text }}
          </button>
        </template>
      </div>
    </div>

    <div v-else class="message-preview-empty-state">
      {{ emptyText }}
    </div>
  </div>
</template>

<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import {
  MESSAGE_TEMPLATES_CHANNEL_TYPE,
  MESSAGE_TEMPLATES_PREVIEW_CLASS,
} from '../constants';

export default {
  name: 'MessagePreview',
  mixins: [messageFormatterMixin],
  props: {
    currentTranslation: {
      type: Object,
      default: () => ({}),
    },

    modalMode: {
      type: Boolean,
      default: true,
    },

    tags: {
      type: Array,
      default: () => ['❶', '❷', '❸', '❹', '❺', '❻', '❼', '❽', '❾', '❿'],
    },

    customHeaderAttachment: {
      type: String,
      default: '',
    },

    hasLabel: {
      type: Boolean,
      default: true,
    },

    emptyText: {
      type: String,
      default: '',
    },
  },
  computed: {
    messageTemplateClass() {
      return MESSAGE_TEMPLATES_PREVIEW_CLASS[
        MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP
      ];
    },

    headerComponent() {
      return this.currentTranslation?.components?.find(
        (component) => component.type === 'HEADER'
      );
    },

    bodyComponent() {
      return this.currentTranslation?.components?.find(
        (component) => component.type === 'BODY'
      );
    },

    footerComponent() {
      return this.currentTranslation?.components?.find(
        (component) => component.type === 'FOOTER'
      );
    },

    buttonsComponent() {
      return this.currentTranslation?.components?.find(
        (component) => component.type === 'BUTTONS'
      );
    },

    headerAttachment() {
      return (
        this.customHeaderAttachment ||
        this.headerComponent?.example?.header_handle[0]
      );
    },

    isButtonsComponentEmpty() {
      if (!this.buttonsComponent || !this.buttonsComponent?.buttons?.length) {
        return true;
      }

      return !this.buttonsComponent.buttons.some((button) => button.text);
    },

    isPreviewEmpty() {
      return !(
        (this.headerComponent &&
          (this.headerComponent.text ||
            this.headerComponent?.example?.header_handle[0] ||
            this.customHeaderAttachment)) ||
        (this.bodyComponent && this.bodyComponent.text) ||
        (this.footerComponent && this.footerComponent.text) ||
        !this.isButtonsComponentEmpty
      );
    },
  },
  methods: {
    formatTextWithLinks(text = '') {
      const splitted = text
        .replace(/{{([1-9]{1}|10)}}/gm, '{{}}')
        .split('{{}}');

      return this.formatMessage(
        splitted
          .map((value, index) => {
            if (index === splitted.length - 1) {
              return value;
            }

            return `${value}${this.tags[index]}`;
          })
          .join(''),
        false,
        false,
        true
      );
    },
  },
};
</script>

<style lang="scss">
@tailwind components;
@layer components {
  .bg-whatsapp {
    @apply bg-[#E5DDD5];
    background-image: url('~assets/images/whatsapp-bg.png');
  }

  .bg-whatsapp-dark {
    @apply bg-gray-950;
    background-image: url('~assets/images/whatsapp-bg-dark.png');
  }
}

.message-preview-container {
  .message-preview-background {
    @apply relative min-h-[12rem] flex-grow;

    .message-preview-message {
      word-break: break-word;

      p {
        @apply mb-0;
      }

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-top: 0.9rem solid var(--white);
        border-left: 0.9rem solid transparent;
        border-right: 0.9rem solid transparent;
        top: 0;
        left: -0.8rem;
      }
    }
  }

  &.message-preview--universal {
    .message-preview-background {
      @apply bg-gray-50;

      .message-preview-message {
        @apply bg-gray-50;

        a {
          @apply text-gray-800;
        }

        &:after {
          // rework once released
          border-top: 0.9rem solid;
          @apply border-gray-100;
        }
      }
    }
  }

  &.message-preview--whatsapp {
    .message-preview-background {
      @apply bg-whatsapp dark:bg-whatsapp-dark flex flex-col items-start justify-end gap-2;

      .message-preview-message {
        @apply bg-white text-gray-800 shadow-md dark:bg-gray-800 dark:text-gray-100;

        .message-preview-message-header {
          .message-preview-message-header-media-container {
            @apply mb-2 w-full rounded-md bg-contain bg-center bg-no-repeat;

            &.message-header--image {
              background-image: url('~assets/images/no-image.png');
            }

            &.message-header--video {
              background-image: url('~assets/images/no-video.png');
            }

            .message-header--preview {
              @apply block aspect-video;
            }

            img,
            video {
              @apply h-auto w-full bg-white object-contain dark:bg-gray-900;
            }
          }
        }

        .message-preview-message-body {
          @apply text-gray-800 dark:text-gray-100;
          a {
            color: #00a5f4 !important;
          }

          p:not(:last-child) {
            @apply mb-3;
          }

          ol {
            @apply ml-4 list-decimal;
          }

          ul {
            @apply ml-4 list-disc;
          }

          blockquote {
            @apply border-l-4 border-l-woot-500 pl-2;
          }
        }

        .message-preview-message-footer {
          @apply mt-2 text-xs text-gray-800 opacity-40 dark:text-gray-100 dark:opacity-60;
        }

        &:after {
          @apply border-t-white dark:border-t-gray-800;
        }
      }

      .message-preview-message-buttons {
        @apply flex w-full flex-col gap-1 bg-transparent;

        button {
          @apply block rounded bg-white px-2 py-1.5 text-sm shadow-sm dark:bg-gray-800;
          color: #00a5f4;
        }
      }
    }
  }

  .message-preview-empty-state {
    @apply flex aspect-video h-full w-full items-center justify-center rounded border bg-gray-50 px-2 py-4 text-center text-sm text-gray-500 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-400;
  }
}
</style>
