<template>
  <div>
    <div v-if="hasMainFolder">
      <woot-button
        class="my-1 flex h-7 w-full max-w-full items-center overflow-hidden text-ellipsis whitespace-nowrap rounded px-2 transition-colors duration-200 hover:!bg-gray-100 dark:hover:!bg-gray-800"
        color-scheme="secondary"
        variant="clear"
        :icon="icon"
        type="button"
        @click="toggleIsFolderExpanded"
      >
        <div
          class="flex flex-row flex-nowrap items-center justify-between gap-2"
        >
          <span class="text-ellipsis">
            {{ label }}
          </span>
        </div>
      </woot-button>

      <div>
        <div class="pl-2.5">
          <slide-animation
            :is-shown="isFolderExpanded || isOpened"
            :duration="250"
          >
            <div>
              <template v-if="isListHasChildren">
                <folders-list-item
                  v-for="item in list"
                  :key="item._id || item.id"
                  :item="item"
                  :menu-options="menuOptions"
                  :is-opened="isOpened"
                >
                  <template #listItem="{ listItem, listItemMenuOptions }">
                    <slot
                      name="listItem"
                      :list-item="listItem"
                      :list-item-menu-options="listItemMenuOptions"
                    />
                  </template>
                </folders-list-item>
              </template>

              <div v-else class="grid w-full pb-2 pl-5 text-gray-500">
                {{ $t('FOLDERS.EMPTY_MESSAGE') }}
              </div>
            </div>
          </slide-animation>
        </div>
      </div>
    </div>

    <template v-else>
      <folders-list-item
        v-for="item in list"
        :key="item._id || item.id"
        :item="item"
        :menu-options="menuOptions"
        :is-opened="isOpened"
      >
        <template #listItem="{ listItem, listItemMenuOptions }">
          <slot
            name="listItem"
            :list-item="listItem"
            :list-item-menu-options="listItemMenuOptions"
          />
        </template>
      </folders-list-item>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SlideAnimation from '../../animations/SlideAnimation.vue';
import FoldersListItem from './FoldersListItem.vue';

export default {
  name: 'FoldersList',
  components: { SlideAnimation, FoldersListItem },
  props: {
    list: {
      type: Array,
      default: () => [],
    },

    menuOptions: {
      type: Object,
      default: () => ({}),
    },

    hasMainFolder: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    icon: {
      type: String,
      default: 'archive',
    },

    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFolderExpanded: false,
    };
  },
  computed: {
    ...mapGetters({
      foldersState: 'folders/getFoldersState',
    }),

    isListHasChildren() {
      return !!this.list.length;
    },
  },
  mounted() {
    this.setupIsFolderExpandedState();
  },
  methods: {
    toggleIsFolderExpanded() {
      this.isFolderExpanded = !this.isFolderExpanded;

      this.$store.dispatch('folders/setFoldersState', {
        state: {
          [`folder_${this.icon}`]: this.isFolderExpanded,
        },
      });
    },

    setupIsFolderExpandedState() {
      const savedIsExpandedState = this.foldersState[`folder_${this.icon}`];

      this.isFolderExpanded =
        typeof savedIsExpandedState === 'boolean'
          ? savedIsExpandedState
          : false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
