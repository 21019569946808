import { frontendURL } from 'dashboard/helper/URLHelper';
import SettingsContent from '../Wrapper';
import Index from './Index';
import CredentialEdit from './components/CredentialEdit.vue';
import CredentialEditAuthorizing from './components/CredentialEditAuthorizing.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/credentials'),
      component: SettingsContent,
      props: (params) => {
        const showBackButton = params.name !== 'settings_credentials_list';
        return {
          headerTitle: 'CHATLYN_CREDENTIALS.HEADER',
          headerButtonText: 'CHATLYN_CREDENTIALS.HEADER_BTN_TXT',
          icon: 'key',
          newButtonRoutes: ['settings_credentials_new'],
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_credentials',
          meta: {
            permissions: ['administrator'],
          },
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_credentials_list',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
        {
          path: 'authorizing',
          component: CredentialEditAuthorizing,
          meta: {
            permissions: ['administrator'],
          },
          name: 'settings_credentials_authorizing',
        },
        {
          path: 'new',
          component: CredentialEdit,
          meta: {
            permissions: ['administrator'],
          },
          name: 'settings_credentials_new',
        },
        {
          path: ':credentialId',
          component: CredentialEdit,
          meta: {
            permissions: ['administrator'],
          },
          name: 'settings_credentials_edit',
        },
      ],
    },
  ],
};
