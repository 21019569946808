var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popover-select"},[_c('v-popover',{attrs:{"placement":_vm.placement,"popover-class":"popover-select__container","trigger":"manual","offset":4,"open":_vm.isPopoverSelectOpen,"disabled":_vm.disabled},on:{"apply-hide":_vm.handleAutoHide}},[_c('div',{staticClass:"h-full",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleIsPopoverSelectOpen.apply(null, arguments)}}},[_vm._t("default")],2),_vm._v(" "),_c('template',{slot:"popover"},[_c('div',{staticClass:"flex h-full max-h-52 flex-col overflow-y-auto overflow-x-hidden"},[(_vm.hasSearch)?_c('label',{staticClass:"popover-select__container__search brd z-10 flex flex-row items-center gap-2 rounded-t-md border-b border-solid bg-white px-2 py-1.5 dark:bg-gray-800"},[_c('fluent-icon',{attrs:{"icon":"search","size":14}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"!h-5 !bg-transparent !p-0 !text-sm !font-normal",staticStyle:{"border":"none"},attrs:{"placeholder":_vm.searchPlaceholder},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})],1):_vm._e(),_vm._v(" "),(_vm.searchedOptions && _vm.searchedOptions.length)?_c('ul',{staticClass:"popover-select__container__list"},_vm._l((_vm.searchedOptions),function(option){return _c('li',{key:option.value,staticClass:"popover-select__container__list__item relative flex flex-row items-center gap-2 rounded",class:{
              'popover-select__container__list__item--selected surface-cta-highlight':
                _vm.value === option.value,
            }},[(option.hasDot)?_c('div',{staticClass:"absolute right-0 top-0 flex items-center justify-center"},[_c('div',{staticClass:"absolute h-2 w-2 animate-ping rounded-full bg-woot-500 dark:bg-woot-400"}),_vm._v(" "),_c('div',{staticClass:"h-2 w-2 rounded-full bg-woot-500 dark:bg-woot-400"})]):_vm._e(),_vm._v(" "),(option.value === 'separator')?_c('div',{staticClass:"h-[1px] w-full bg-gray-100 dark:bg-gray-700"}):_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.showTooltip ? option.label : '',
                classes: ['tooltip-important'],
              }),expression:"{\n                content: showTooltip ? option.label : '',\n                classes: ['tooltip-important'],\n              }"}],staticClass:"w-full",attrs:{"variant":"clear","size":"small","color-scheme":"secondary","icon":option.icon,"disabled":option.disabled,"data-testid":option.dataTestid},on:{"click":function($event){return _vm.handleOptionSelect(option.value)}}},[_vm._v("\n              "+_vm._s(option.label)+"\n            ")])],1)}),0):(_vm.$slots['empty-state'])?_vm._t("empty-state"):_c('div',{staticClass:"flex h-full w-full items-center justify-center p-2 text-center text-sm text-gray-500",staticStyle:{"word-break":"break-word"}},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_GENERAL.MESSAGES.NO_OPTIONS'))+"\n        ")])],2)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }