<template>
  <div class="mb-4">
    <button
      class="w-10 h-10 my-2 flex items-center justify-center rounded-full relative transition-all duration-200 focus:outline-none outline-none"
      :class=" {
        'hover:bg-green-200 bg-green-100 dark:bg-green-800 dark:bg-opacity-50 hover:bg-opacity-80 dark:hover:bg-opacity-80' : isNotificationPanelActive,
        'hover:bg-green-100 hover:dark:bg-green-800 hover:dark:bg-opacity-50' : unreadCount,
        'hover:bg-gray-100 hover:dark:bg-gray-700 dark:bg-opacity-50 hover:bg-opacity-80 text-gray-500 dark:text-gray-400' : !isNotificationPanelActive && !unreadCount
      }
      "
      @click="openNotificationPanel"
    >
      <fluent-icon
        icon="alert"
        :class="{
          'text-green-500 dark:text-green-400': isNotificationPanelActive || unreadCount,
        }"
      />
      <span
        v-if="unreadCount"
        class="bg-green-500 dark:bg-green-700 absolute -top-0.5 -right-1 px-1 h-4 w-4 text-xxs rounded-full animate-ping duration-500"
      >
      </span>
      <span
        v-if="unreadCount"
        class="text-white leading-4 font-bold bg-green-500 dark:bg-green-700 dark:bg-opacity-70 absolute -top-0.5 -right-1 px-1 h-4 text-xxs min-w-[1rem] rounded-full outline outline-white dark:outline-gray-800"
      >
        {{ unreadCount }}
      </span>
      
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
    }),
    unreadCount() {
      if (!this.notificationMetadata.unreadCount) {
        return '';
      }

      return this.notificationMetadata.unreadCount < 100
        ? `${this.notificationMetadata.unreadCount}`
        : '99+';
    },
    isNotificationPanelActive() {
      return this.$route.name === 'notifications_index';
    },
  },
  methods: {
    openNotificationPanel() {
      if (this.$route.name !== 'notifications_index') {
        this.$emit('open-notification-panel');
      }
    },
  },
};
</script>
