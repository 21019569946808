import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const primaryMenuItems = (accountId) => [
  /*
   {
     icon: 'mail-inbox',
     key: 'inboxView',
     label: 'SIDEBAR.INBOX_VIEW',
     featureFlag: FEATURE_FLAGS.INBOX_VIEW,
     toState: frontendURL(`accounts/${accountId}/inbox-view`),
     toStateName: 'inbox_view',
   },
   */
  {
    icon: 'chat',
    key: 'conversations',
    label: 'SIDEBAR.CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
  },
  {
    icon: 'book-contacts',
    key: 'contacts',
    label: 'SIDEBAR.CONTACTS',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
  },
  //chatlyn
  {
    icon: 'mail-read',
    key: 'messageTemplates',
    label: 'CHATLYN_SIDEBAR.MESSAGE_TEMPLATES',
    featureFlag: FEATURE_FLAGS.MESSAGE_TEMPLATES,
    toState: frontendURL(`accounts/${accountId}/message-templates/whatsapp`),
    toStateName: 'whatsapp_message_templates',
    //roles: ['administrator'],
  },
  //chatlyn
  {
    icon: 'chatlyn-automations',
    key: 'automations',
    label: 'CHATLYN_SIDEBAR.JOURNEY_BUILDER',
    featureFlag: FEATURE_FLAGS.AUTOMATIONS,
    toState: frontendURL(`accounts/${accountId}/automations`),
    toStateName: 'automations_dashboard',
    //roles: ['administrator'],
  },
  {
    icon: 'arrow-trending-lines',
    key: 'reports',
    label: 'SIDEBAR.REPORTS',
    featureFlag: FEATURE_FLAGS.REPORTS,
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'account_overview_reports',
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'SIDEBAR.CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'ongoing_campaigns',
  },
  /*
  {
    icon: 'library',
    key: 'helpcenter',
    label: 'SIDEBAR.HELP_CENTER.TITLE',
    featureFlag: FEATURE_FLAGS.HELP_CENTER,
    alwaysVisibleOnChatwootInstances: false,
    toState: frontendURL(`accounts/${accountId}/portals`),
    toStateName: 'default_portal_articles',
  },
  */
  {
    icon: 'settings',
    key: 'settings',
    label: 'SIDEBAR.SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
  },
];

export default primaryMenuItems;
