import { render, staticRenderFns } from "./PortalSwitch.vue?vue&type=template&id=27f9b01a&scoped=true&"
import script from "./PortalSwitch.vue?vue&type=script&lang=js&"
export * from "./PortalSwitch.vue?vue&type=script&lang=js&"
import style0 from "./PortalSwitch.vue?vue&type=style&index=0&id=27f9b01a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f9b01a",
  null
  
)

export default component.exports